import React, { useEffect, useState } from 'react'
import close from '@/assets/images/close.svg'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import burger from '@/assets/images/burger.svg'
import back from '@/assets/images/arrow-back.svg'
import backV2 from '@/assets/images/arrow-back-v2.svg'
import logo from '@/assets/images/svg/logo-mini.svg'

interface HeaderProps {
  title?: string
  onBackClick?: () => void
  showBtn?: boolean
  isLastQuestion?: boolean
  isLastStep?: boolean
  hideHeader?: boolean
  hideBtnHeader?: boolean
  hideBackHeader?: boolean
  hideNav?: boolean
  isPage?: boolean
  step?: number
  fixed?: boolean
  isLogo?: boolean
  version?: number
}
export function Header(props: HeaderProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [showOnLastQuesttion, setShowOnLastQuesttion] = useState(props.isLastQuestion)
  useEffect(() => {
    setShowOnLastQuesttion(false)
  }, [props.isLastQuestion])

  return (
    <>
      <div
        className={`${openMenu ? 'translate-x-0' : 'translate-x-full'}  fixed top-0 right-0 h-full w-11/12 z-40 bg-white border border-gray transition-all duration-300 flex flex-col`}
      >
        <div className="border-b border-gray px-4 py-2.5 flex items-center justify-between pl-6">
          <h2 className="text-xl3 m-0 p-0 font-bold">{t('Docs')}</h2>
          <div
            className="shadow-close rounded-r3 bg-white w-5 relative transition-all active:scale-95 cursor-pointer"
            onClick={() => setOpenMenu(false)}
          >
            <img className="w-full" width="20" height="20" src={close} alt="close" />
          </div>
        </div>
        <div className="py-6 pb-8 px-7 flex-grow flex-shrink basic-auto flex flex-col justify-between">
          <ul>
            <li>
              <Link
                className="transition-all hover:text-primary text-base leading-loose pl-4 block"
                to="/faq"
              >
                {t('FAQ')}
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className="transition-all hover:text-primary text-base leading-loose pl-4 block"
                to="/privacy-policy"
              >
                {t('Privacy Policy')}
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className="transition-all hover:text-primary text-base leading-loose pl-4 block"
                to="/money-back"
              >
                {t('Money to Back')}
              </Link>
            </li>
            <li className="mt-4">
              <Link
                className="transition-all hover:text-primary text-base leading-loose pl-4 block"
                to="/offer-policy"
              >
                {t('Offer Policy')}
              </Link>
            </li>
          </ul>
          <div>
            <p className="text-xs text-left">{t('We will be glad to assist you via email.')}</p>
            <p className="text-xs text-left">{t('Please send your questions and feedback to')}</p>
            <p className="text-xs text-left transition-all hover:text-green">
              <a href="mailto:  support@fit4me-life.com"> support@fit4me-life.com</a>
            </p>
          </div>
        </div>
      </div>
      <div
        className={`${props.fixed ? 'sticky top-0 left-0 bg-white -mt-4 pt-4 ' : ''} pb-4 relative z-20 transition-all ${props.hideNav ? '-translate-y-full opacity-0 h-0' : 'translate-y-0'}`}
      >
        <div>
          <div className="flex justify-center items-center relative  min-h-6">
            {!props.showBtn &&
              props.step === 0 &&
              !props.hideBtnHeader &&
              !props.hideBackHeader && (
                <div
                  className="absolute top-1/2 left-0 -translate-y-1/2 cursor-pointer transition-all duration-300 active:scale-95"
                  onClick={() => navigate('/')}
                >
                  {props?.version === 2 ? (
                    <img src={backV2} alt="arrow back" />
                  ) : (
                    <img src={back} alt="arrow back" />
                  )}
                </div>
              )}
            <div
              className={`${props.showBtn && !props.hideBtnHeader && !props.hideBackHeader ? 'opacity-100 visible' : 'opacity-0  invisible'} cursor-pointer absolute top-1/2 left-0 -translate-y-1/2 transition-all duration-300 active:scale-95`}
              onClick={props.onBackClick}
            >
              <img src={back} alt="arrow back" />
            </div>
            {props.isLogo ? (
              <div>
                <img src={logo} alt="Logo Fit4Me" />
              </div>
            ) : (
              <>
                <h3
                  className={`${
                    !props.hideHeader && !showOnLastQuesttion
                      ? 'opacity-100 visible'
                      : 'opacity-0  invisible'
                  } ${!props.isPage ? 'relative -top-2 text-base1' : ''} ${props.hideBackHeader ? '!top-0' : ''} font-semibold text-center font-girloy transition-all duration-300`}
                >
                  {props.title}
                </h3>
                <div
                  className={`${
                    props.hideHeader || showOnLastQuesttion
                      ? 'opacity-100 visible'
                      : 'opacity-0  invisible'
                  }  text-base font-semibold text-center transition-all duration-300`}
                >
                  <h2 className="block text-xl3 font-girloy pt-0 mb-0 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
                    Fit4Me
                  </h2>
                </div>
              </>
            )}
            <div
              className={`${props.hideBtnHeader || props.version === 2 ? 'opacity-0 invisible' : 'opacity-100 visible'} transition-all duration-300 cursor-pointer absolute top-1/2 right-0 -translate-y-1/2 transition-all active:scale-95 burger`}
              onClick={() => setOpenMenu(!openMenu)}
            >
              <img src={burger} alt="burger" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
