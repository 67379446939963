import React, { useState } from 'react'
import { Answer, Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import Image from '@/components/Image/Image'

interface DrinkSelectProps {
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
}

const DrinkSelect: React.FC<DrinkSelectProps> = (props) => {
  const choosedValueRaw = getValue(props.name, props.answers)
  const initialValue = choosedValueRaw
    ? parseInt(Array.isArray(choosedValueRaw) ? choosedValueRaw[0] : choosedValueRaw, 10) || 0
    : 0
  const [filledGlasses, setFilledGlasses] = useState<number>(initialValue)

  const FL_OZ_PER_GLASS = 8.45
  const L_PER_GLASS = 0.25

  const handleGlassClick = (glassNumber: number) => {
    setFilledGlasses(glassNumber)
    props.onAnswer({
      [props.name]: [glassNumber.toString()],
    })
  }

  const totalFlOz = (filledGlasses * FL_OZ_PER_GLASS).toFixed(2)
  const totalLiters = (filledGlasses * L_PER_GLASS).toFixed(2)

  return (
    <div className="">
      <p className="text-center text-[#17181D] font-bold text-xl4 font-girloy mb-2">
        How much water <br /> do you drink each day?
      </p>
      <p className="text-center text-[#5F626F] font-normal text-xl2 font-girloy mb-8">
        Drinking enough water can boost <br /> calorie burn by 25% at rest.
      </p>
      <p className="text-center text-[#5F626F] font-normal text-md font-girloy mb-5">
        {filledGlasses} glass{filledGlasses !== 1 ? 'es' : ''} – {totalFlOz} fl oz ({totalLiters} l)
      </p>

      <div className="grid grid-cols-5 gap-4 mb-4 px-4 py-4">
        {Array.from({ length: 10 }, (_, i) => {
          const glassNumber = i + 1
          const isFilled = glassNumber <= filledGlasses
          return (
            <div
              key={glassNumber}
              onClick={() => handleGlassClick(glassNumber)}
              className="cursor-pointer transition-all duration-300"
            >
              <Image
                className="h-[82px] w-auto object-contain"
                alt={`Glass ${glassNumber}`}
                src={
                  isFilled
                    ? '/img/quiz-wallpilates-v6/water-full.png'
                    : '/img/quiz-wallpilates-v6/water-empty.webp'
                }
                webp={
                  isFilled
                    ? '/img/quiz-wallpilates-v6/water-full.png'
                    : '/img/quiz-wallpilates-v6/water-empty.webp'
                }
                height={82}
              />
            </div>
          )
        })}
      </div>

      <div className="relative rounded-[53px] border border-[rgba(62,149,100,0.31)]">
        <Image
          src="/img/quiz-wallpilates-v6/arrow-to-right-green.svg"
          alt="Arrow icon"
          className="absolute right-3 top-[9px]"
        />
        <div
          className="text-[#17181D] text-center uppercase text-base font-semibold font-girloy py-2.5"
          onClick={() => {
            console.log('test', 1)
            props.onAnswer({
              water: ['1'],
            })
            props.triggerNextStep()
          }}
        >
          I Just Drink coffee and tea
        </div>
      </div>
    </div>
  )
}

export default DrinkSelect
