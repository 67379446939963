import React from 'react'
import ReactDOM from 'react-dom/client'
import '@/assets/scss/style.scss'
import i18n from './i18n'
import { QueryClient, QueryClientProvider } from 'react-query'
import ErrorBoundary from '@/components/ErrrosBoundary'
import { AMPLITUDE_RATE } from './constants/variables'
import ReactPixel from 'react-facebook-pixel'
import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import App from './App'

ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID ?? '')
ReactPixel.fbq('dataProcessingOptions', ['LDU'], 1, 0)

amplitude.init('b2a79bfa738450505fcce812db525ad1', { defaultTracking: true })
const sessionReplayTracking = sessionReplayPlugin({ sampleRate: AMPLITUDE_RATE })
amplitude.add(sessionReplayTracking)

document.documentElement.lang = i18n.language

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </ErrorBoundary>,
)
