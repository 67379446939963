import React from 'react'
import { PaywallContent } from '@/types/types'
import { useTranslation } from 'react-i18next'
import iconBolt from '@/assets/images/wallpilatesV6/start/bolt.svg'

interface PlansHeaderProps {
  paywallContent: PaywallContent | undefined
  goal: string[] | string | undefined
  goalWeight: string[] | string | undefined
}

export const PlansHeaderV2 = (props: PlansHeaderProps) => {
  const { t } = useTranslation()
  const { paywallContent, goal, goalWeight } = props
  return (
    <>
      {paywallContent?.choosePlanBlock && (
        <div className="flex flex-row justify-between items-center gap-2">
          <div
            className={
              'flex bg-white flex-col items-center checkbox font-semibold border relative border-border mb-2 mt-0 rounded-xl px-3 min-h-16 cursor-pointer transition duration-300 w-full py-6'
            }
            style={{
              boxShadow:
                'rgba(215, 229, 218, 0.8) 0px 4px 11px 0px, rgb(223 234 226 / 20%) -1px -4px 2px 4px inset',
            }}
          >
            <img
              src={iconBolt}
              className="absolute -top-4 bg-[#13D16A] rounded-3xl p-2"
              alt="icon"
            />
            <p className="text-[#5F626F] font-girloy text-base font-normal">{t('Goal')}</p>
            <p className="text-[#17181D] font-girloy text-xl font-extrabold">{goal}</p>
          </div>

          <div
            className={
              'flex bg-white flex-col items-center checkbox font-semibold border relative border-border mb-2 mt-0 rounded-xl px-3 min-h-16 cursor-pointer transition duration-300 w-full py-6'
            }
            style={{
              boxShadow:
                'rgba(215, 229, 218, 0.8) 0px 4px 11px 0px, rgb(223 234 226 / 20%) -1px -4px 2px 4px inset',
            }}
          >
            <img
              src={iconBolt}
              className="absolute -top-4 bg-[#13D16A] rounded-3xl p-2"
              alt="icon"
            />
            <p className="text-[#5F626F] font-girloy text-base font-normal">{t('Target weight')}</p>
            <p className="text-[#17181D] font-girloy text-xl font-extrabold">
              {goalWeight?.[0]} {goalWeight?.[1]}
            </p>
          </div>
        </div>
      )}
    </>
  )
}
