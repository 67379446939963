import { getValue } from '@/helpers/getObjectValue'
import { Answers } from '@/types/types'

export const generatePromoCode = (answers: Answers): string => {
  const today = new Date()
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const month = months[today.getMonth()]
  const day = today.getDate().toString().padStart(2, '0')

  const yourName = getValue('your-name', answers)
  const firstName = typeof yourName === 'string' ? yourName : ''

  return `${firstName.replace(/\s+/g, '_')}_${month}${day}`
}
