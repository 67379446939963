import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/Layout'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { useQuery } from 'react-query'
import { Answers, Customer, PaywallContent, Plan, PlanHubspot } from '@/types/types'
import Timer from '@/components/PaywallUI/Timer'
import { Plans } from '@/components/PaywallUI/Plans'
import Review from '@/components/QuestionsUI/Review'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import HeroV2 from '../PaywallUI/HeroV2'
import { getValue } from '@/helpers/getObjectValue'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import { trackLead } from '@/helpers/facebookPixelEvents'
import Percent45Overlay from '@/components/PaywallUI/Percent45Overlay'
import { PaymentPlanApi } from '@/app/api/payment-plan-api/payment-plan-api'
import { CHECKOUT_URL, PRIMER_ID } from '@/constants/variables'
import { SessionStorageService } from '@/services/session-storage-service'
import Image from '../Image/Image'
import { encryptField } from '@/helpers/encryptField'
import { PlansHubspot } from '@/components/PaywallUI/PlansHubspot'
import PlansTimerTicketPink from '@/components/PaywallUI/PlansTimerTicketPink'
import { PlansHeaderV2 } from '@/components/PaywallUI/PlansHeaderV2'

import arrowIcon from '@/assets/images/wallpilatesV6/paywall/arrow.svg'
import questionIcon from '@/assets/images/wallpilatesV6/paywall/icon-question.svg'

const transition = '300ms all, 300ms all'
const transitionStylesModal = {
  entered: {
    top: '0px',
  },
  exited: {
    top: '100%',
  },
}
const defaultStyleModal = {
  transition,
  top: '100%',
}

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export interface PaywallStatus {
  currentStep: number
  showModal: boolean
  wasShowing?: boolean
}

export default function PaywallV2(props: PaywallProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const nodeRef = useRef(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const planElement = useRef<HTMLDivElement>(null)
  const topPage = useRef<HTMLDivElement>(null)

  const [activePlan, setActivePlan] = useState(1)
  const [paywallStatus, setPaywallStatus] = useState<PaywallStatus>(
    (SessionStorageService.get('paywallStatus') as PaywallStatus) || {
      currentStep: 0,
      showModal: false,
    },
  )

  const storedData = SessionStorageService.get('quizResult')
  const answers: Answers = storedData || false

  const userId = searchParams.get('_id')
  const [loadTimer, setLoadTimer] = useState(false)
  const [goal, setGoal] = useState(getValue('main-goal', answers))
  const [goalWeight, setGoalWeight] = useState(getValue('goal-weight', answers))
  const [bodyFatIndex, setBodyFatIndex] = useState(
    getValue('range-current-body', answers)?.[0] ?? '0',
  )

  const [customer, setCustomer] = useState<Customer>({
    city: SessionStorageService.get('city'),
    email: getValue('email', answers) as string,
    address: SessionStorageService.get('city'),
    paraml: encryptField((getValue('your-lastname', answers) as string) ?? ''), // 'John',
    paramf: encryptField((getValue('your-name', answers) as string) ?? ''), // 'John',
    postalCode: SessionStorageService.get('postalCode') || '90071',
    countryCode: SessionStorageService.get('countryCode') || 'US',
    customerId: SessionStorageService.get('customerId') || '',
  })

  const isDiscountPrice = paywallStatus.currentStep >= 1

  const { data: plans, isLoading: isLoadingPlans } = useQuery({
    queryKey: ['plans', isDiscountPrice],
    queryFn: () =>
      PRIMER_ID
        ? PaymentPlanApi.findPrimer(isDiscountPrice ? '2' : '1')
        : PaymentPlanApi.find('stripeV1'),
    select(data: any) {
      if (PRIMER_ID) {
        return data.items.filter(
          (plan: any) => plan.properties.producttype === 'plan',
        ) as PlanHubspot[]
      } else {
        const formatData = data.items.map((item: any) => ({
          name: item.name,
          ...item.plans.find((plan) => plan.type === 'stripeV1'),
        }))

        return formatData as Plan[]
      }
    },

    retry: 5,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  })

  const { data: paywallContent } = useQuery({
    queryKey: ['paywallContent'],
    enabled: !!props.lang && !!props.paywallFile,
    queryFn: async () => {
      const response: PaywallContent = await import(
        `../../locales/${props.lang}/${props.paywallFile}`
      )
      return response
    },
    onError(error) {
      console.error('Error fetching paywall data:', error)
    },
  })

  const loadUserData = async (userId: string): Promise<{ status: number; body: any }> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/account/quiz/data?_id=${userId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      )

      const data = await response.json()
      return { status: response.status, body: data }
    } catch (e) {
      return { status: 412, body: { error: e } }
    }
  }

  const handleUserData = async (userId: string) => {
    try {
      const endTime = new Date()
      endTime.setTime(endTime.getTime() + 10 * 60 * 1000)
      sessionStorage.setItem('quizResult', JSON.stringify(answers))
      sessionStorage.setItem('endTimer', endTime.toISOString())
      setLoadTimer(true)

      const { body: data } = await loadUserData(userId)

      sessionStorage.setItem('customerId', data?._id as string)
      localStorage.setItem(
        'email',
        data?.preCreateEmails[data?.preCreateEmails?.length - 1] as string,
      )

      setCustomer({
        customerId: data?._id,
        email: data?.preCreateEmails[data?.preCreateEmails?.length - 1] as string,
        paramf: encryptField(data?.profile?.name as string),
        paraml: encryptField(''),
        postalCode: SessionStorageService.get('postalCode') || '90071',
        countryCode: SessionStorageService.get('countryCode') || 'US',
      })

      setGoal(data?.profile?.goals?.name || '')
      const isMetricUS = data?.metricType === 'US'
      const weightValue = parseInt(
        isMetricUS
          ? (data?.profile?.condition?.weight?.wanted?.pounds ?? '0')
          : (data?.profile?.condition?.weight?.wanted?.kilograms ?? '0'),
      ).toString()

      const weightUnit = isMetricUS ? 'lbs' : 'kg'

      setGoalWeight([weightValue, weightUnit])
      setBodyFatIndex(data?.profile?.condition?.weight?.index ?? '0')
      setPaywallStatus({
        currentStep: 1,
        showModal: false,
      })

      setLoadTimer(false)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    trackLead()

    const paywallStatusString = sessionStorage.getItem('paywallStatus')
    if (!paywallStatusString) handleChangePaywallStatus(false, 0)

    if (userId) {
      handleUserData(userId)
    }
  }, [])

  useEffect(() => {
    if (!answers && !userId) {
      navigate('/')
    }
  }, [answers])

  const handleCheckout = () => {
    const params = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      planId: plans?.[activePlan]?._id ?? plans?.[activePlan]?.id ?? '',
      ...customer,
    }

    const checkoutUrl = new URL(CHECKOUT_URL)

    for (const key in params) {
      checkoutUrl.searchParams.append(key, params[key])
    }

    const dietVegan = getValue('type-of-diet', answers) !== 'Traditional'

    if (isDiscountPrice) checkoutUrl.searchParams.append('isDiscountPrice', 'true')
    if (dietVegan) checkoutUrl.searchParams.append('vegan', 'true')
    if (paywallStatus.currentStep === 0) handleChangePaywallStatus(true, 1)

    window.location.href = checkoutUrl.href
  }

  const handleSavePaywallStatus = (modal: boolean, step?: number) => {
    SessionStorageService.set('paywallStatus', {
      currentStep: step ?? paywallStatus.currentStep,
      showModal: modal,
    })
  }

  const handleChangePaywallStatus = (modal: boolean, step?: number) => {
    setPaywallStatus({ currentStep: step ?? paywallStatus.currentStep, showModal: modal })
    handleSavePaywallStatus(modal, step)
  }

  const handleScrollToPlan = () => {
    const planOffsetTop = planElement.current?.offsetTop || 0
    containerRef.current?.scroll(0, planOffsetTop - 70)
  }

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  if (isLoadingPlans)
    return (
      <div className="fixed top-0 z-30 left-0 bg-white w-full h-[calc(100%-60px)]">
        <div className="spinner z-30"></div>
      </div>
    )

  return (
    (answers || userId) && (
      <Layout>
        <div ref={containerRef} className="overflow-y-auto min-h-full p-0 overflow-x-hidden">
          <div ref={topPage}></div>
          <div className="px-4 pb-2 pt-2 sticky top-0 bg-white z-50 flex items-center justify-between">
            <div className="w-[150px]">
              {paywallStatus.currentStep === 0 && (
                <p className="text-base text-[#5F626F]">
                  <b>61%</b> discount reserved for
                </p>
              )}
              {isDiscountPrice && (
                <p className="text-base text-[#5F626F]">
                  <b>71%</b> discount reserved for
                </p>
              )}
            </div>
            <div className="flex flex-row bg-white rounded-[123px] shadow-[0px_10px_20px_0px_rgba(102,215,77,0.16),0px_20px_50px_0px_rgba(122,236,110,0.26)] items-center p-2 pl-3 gap-2">
              <Timer
                loadTimer={loadTimer}
                className={'text-[#61CE75] text-xl font-bold font-girloy'}
              />
              <Button
                onClick={handleScrollToPlan}
                variant="plan"
                className="text-base font-girloy "
              >
                {t('get my plan')}
              </Button>
            </div>
          </div>
          {isDiscountPrice && (
            <div className="px-4">
              <p className="text-center line-through">
                Previous discount: <span className="text-green2">61%</span>
              </p>
              <div className="bg-[#8DECB4] flex items-center mt-3 mb-6 py-3 px-4 rounded-2xl">
                <span className="text-[50px] mr-2">🎁</span>
                <p className="text-center font-bold">
                  Get your Personal Wall Pilates Plan with up to{' '}
                  <span className="text-plan">71%</span> discount
                </p>
              </div>
            </div>
          )}
          <HeroV2 bodyFatIndex={bodyFatIndex} answers={answers} />
          <p className="px-4 text-xs font-girloy font-medium text-[#8C8F9D] text-center leading-6 py-5">
            This is not a guarantee or promise of results
          </p>

          <div
            id="plan"
            ref={planElement}
            className="px-4 py-5 rounded-b-[32px] bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0%,_#FFF_27%)] shadow-[0px_14px_38px_0px_rgba(42,97,48,0.05),_0px_-14px_38px_0px_rgba(42,97,48,0.05)]"
          >
            <p className="mb-7 text-center text-[#17181D] font-bold text-xl4 font-girloy">
              Your Wall Pilates
              <br />
              Workout Plan is ready
            </p>
            <PlansHeaderV2 paywallContent={paywallContent} goal={goal} goalWeight={goalWeight} />

            <PlansTimerTicketPink />

            <div className="mt-8">
              {plans &&
                paywallContent &&
                (PRIMER_ID ? (
                  <PlansHubspot
                    activePlan={activePlan}
                    isDiscountPrice={isDiscountPrice}
                    handleCheckout={handleCheckout}
                    handlePlan={handlePlan}
                    paymentMethod={props.paymentMethod}
                    descriptionTrial={paywallContent.plans?.descriptionTrial}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    plans={plans}
                    version={2}
                  />
                ) : (
                  <Plans
                    activePlan={activePlan}
                    isDiscountPrice={isDiscountPrice}
                    handleCheckout={handleCheckout}
                    handlePlan={handlePlan}
                    paymentMethod={props.paymentMethod}
                    descriptionTrial={paywallContent.plans?.descriptionTrial}
                    plans={plans}
                    version={2}
                  />
                ))}
            </div>
          </div>

          {paywallContent?.checkoutBlock && (
            <div className="p-0 mt-8 px-4">
              <h3
                className="text-xl3 font-girloy font-bold text-center mb-4"
                dangerouslySetInnerHTML={{ __html: paywallContent.checkoutBlock.title }}
              />
              <div className="grid grid-cols-4 gap-2 place-items-center">
                {paywallContent?.checkoutBlock.items?.map((el) => (
                  <div
                    key={el}
                    className="w-full h-[55px] flex items-center justify-center rounded-[8px] border border-[#EDF1F5] bg-[#FFF]"
                  >
                    <Image
                      className="max-w-full max-h-full object-contain px-2"
                      src={el}
                      alt="Image"
                    />
                  </div>
                ))}
              </div>
            </div>
          )}

          {paywallContent?.benefitsBlock && (
            <div className="px-4 py-10 rounded-[32px] bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0%,_#FFF_27%)] shadow-[0px_14px_38px_0px_rgba(42,97,48,0.05),_0px_-14px_38px_0px_rgba(42,97,48,0.05)] my-8">
              <h3
                className="text-xl4 font-girloy font-bold text-center"
                dangerouslySetInnerHTML={{ __html: paywallContent.benefitsBlock.title }}
              />
              {paywallContent?.benefitsBlock?.items && (
                <ul className="list-none">
                  {paywallContent?.benefitsBlock?.items?.map((el, index) => (
                    <li className="flex items-center mt-6 gap-5" key={index}>
                      <div className="flex items-center justify-center">
                        <Image className="w-12" src={el.src} alt="Icon" />
                      </div>
                      <p
                        className="text-[#17181D] text-xl font-girloy font-semibold leading-5 [&>mark]:bg-transparent [&>mark]:block [&>mark]:text-[#5F626F] [&>mark]:font-medium [&>mark]:text-base"
                        dangerouslySetInnerHTML={{ __html: el.text ?? '' }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {paywallContent?.featuredBlock && (
            <div>
              <h3
                className="text-xl3 font-bold font-girloy text-center mb-3"
                dangerouslySetInnerHTML={{ __html: paywallContent.featuredBlock.title }}
              />
              <div className="flex items-center justify-center flex-wrap">
                {paywallContent?.featuredBlock.items?.map((el) => (
                  <Image className="max-h-6 m-3 mx-2" key={el} src={el} alt="Image" />
                ))}
              </div>
            </div>
          )}

          {paywallContent?.testimonialsBlock && (
            <div className="mt-10 px-1">
              <h3
                className="text-xl4 font-bold font-girloy text-center mb-5"
                dangerouslySetInnerHTML={{ __html: paywallContent?.testimonialsBlock.title ?? '' }}
              />

              {paywallContent?.testimonialsBlock?.reviews?.map((el) => (
                <Review
                  key={el.title + el.name}
                  title={el.title}
                  src={el.src}
                  srcWebp={el.srcWebp}
                  quote={el.quote}
                  stars={el.stars}
                  name={el.name}
                  mainTitle={''}
                  version={2}
                />
              ))}
            </div>
          )}

          {paywallContent?.faqBlock && (
            <div className="mt-12 px-3">
              <h3
                className="text-xl3 font-bold font-girloy text-center mb-5"
                dangerouslySetInnerHTML={{ __html: paywallContent.faqBlock.title }}
              />
              <Accordion type="single" collapsible>
                {paywallContent?.faqBlock.items?.map((item, index) => (
                  <AccordionItem
                    key={index}
                    className="flex bg-white flex-col items-center checkbox font-semibold border relative border-border my-0 rounded-xl px-3 min-h-16 cursor-pointer transition duration-300 w-full py-1"
                    style={{
                      boxShadow:
                        'rgba(215, 229, 218, 0.8) 0px 4px 11px 0px, rgb(223 234 226 / 20%) -1px -4px 2px 4px inset',
                    }}
                    value={`item-${index}`}
                  >
                    <AccordionTrigger className="flex-1 py-4 px-3 text-sm transition-all  [&[data-state=open]]:text-[#61CE75] [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                      <div className="flex items-center justify-between gap-5 font-girloy font-bold">
                        <Image src={questionIcon} alt="icon question" />
                        {item.title}
                      </div>
                      <Image
                        className="h-[24px] w-[24px] shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                        src={arrowIcon}
                        alt="arrow"
                      />
                    </AccordionTrigger>
                    <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                      <p className="pb-3 text-left text-light text-[#5F626F] font-girloy font-medium text-md px-3">
                        {item.text}
                      </p>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}

          <div
            id="plan"
            ref={planElement}
            className="px-4 pt-1 pb-5 rounded-b-[32px] bg-[linear-gradient(180deg,_rgba(255,255,255,0)_0%,_#FFF_27%)] shadow-[0px_14px_38px_0px_rgba(42,97,48,0.05),_0px_-14px_38px_0px_rgba(42,97,48,0.05)]"
          >
            <p className="mt-12 mb-7 text-center text-[#17181D] font-bold text-xl4 font-girloy">
              Your Wall Pilates
              <br />
              Workout Plan is ready
            </p>
            <PlansHeaderV2 paywallContent={paywallContent} goal={goal} goalWeight={goalWeight} />

            <PlansTimerTicketPink />

            <div className="mt-8">
              {plans &&
                paywallContent &&
                (PRIMER_ID ? (
                  <PlansHubspot
                    activePlan={activePlan}
                    isDiscountPrice={isDiscountPrice}
                    handleCheckout={handleCheckout}
                    handlePlan={handlePlan}
                    paymentMethod={props.paymentMethod}
                    descriptionTrial={paywallContent.plans?.descriptionTrial}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    plans={plans}
                    version={2}
                  />
                ) : (
                  <Plans
                    activePlan={activePlan}
                    isDiscountPrice={isDiscountPrice}
                    handleCheckout={handleCheckout}
                    handlePlan={handlePlan}
                    paymentMethod={props.paymentMethod}
                    descriptionTrial={paywallContent.plans?.descriptionTrial}
                    plans={plans}
                    version={2}
                  />
                ))}
            </div>
          </div>

          {paywallContent?.moneyBackBlock && (
            <div className="px-3 pt-5">
              <Image
                className="w-13 mx-auto"
                src={paywallContent?.moneyBackBlock.img}
                alt="100% money-back guarantee"
              />
              <h3
                className="text-xl3 font-bold font-girloy text-center my-3"
                dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.title }}
              ></h3>
              <p className="text-base font-girloy font-normal text-center mb-3">
                {paywallContent?.moneyBackBlock.content}
              </p>
              <p className="text-xs font-normal font-girloy text-center text-[#5F626F] leading-5">
                {paywallContent?.moneyBackBlock.description}{' '}
                <Link
                  className="transition-all hover:text-primary text-[#17181D] underline"
                  to="/money-back"
                  dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.link }}
                ></Link>
              </p>
            </div>
          )}
          {paywallContent?.copyright && (
            <p
              className="mt-5 px-3 py-5 text-center font-girloy text-xs3 font-normal text-[#5F626F] [&>span]:px-px leading-4 border-t-[#d7e5dacc] border-t"
              dangerouslySetInnerHTML={{ __html: paywallContent.copyright }}
            ></p>
          )}

          <Transition
            nodeRef={nodeRef}
            timeout={100}
            onExited={() => topPage.current?.scrollIntoView()}
            in={isDiscountPrice && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <Percent45Overlay
                    isShow={paywallStatus.showModal && isDiscountPrice}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>
        </div>
      </Layout>
    )
  )
}
