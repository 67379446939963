import initCurrencyCode from '@/helpers/initCurrencyCode'

export const getCountryCode = async () => {
  const restrictedCountries = ['RU', 'CU', 'IR', 'KP', 'SY']
  const restrictedRegions = ['Crimea', 'Donetsk', 'Luhansk']

  try {
    const response = await fetch('https://ipinfo.io/json', { method: 'GET' })

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.status}`)
    }

    const data = await response.json()
    const { country, region } = data

    if (restrictedCountries.includes(country) || restrictedRegions.includes(region)) {
      throw new Error('Access-Denied!')
    }

    initCurrencyCode(country)

    return data
  } catch (error) {
    console.error('Error fetching the country code:', error)

    if ((error as Error).message === 'Access-Denied!') {
      throw error
    }

    return null
  }
}
