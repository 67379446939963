import React from 'react'

interface progressProps {
  stepsLength: number
  questionsLength: number
  currentQuestion: number
  currentStep: number
  version?: number
}
export function Progress(props: progressProps) {
  const countStep = props.stepsLength - 1
  const renderList = () => {
    const listItems: JSX.Element[] = []
    for (let i = 0; i < countStep; i++) {
      const isFirstStep = props.currentStep === 0 && i === 0
      if (props.version === 2) {
        if (!isFirstStep && i === 0) continue

        listItems.push(
          <li
            className={`${isFirstStep ? 'w-full contents' : ''} ${i <= props.currentStep ? 'before:bg-tickGreen' : 'before:bg-progress'} w-12 before:hidden after:hidden first:before:white mini before:white progress h-1 relative after:bg-progress `}
            key={i}
          >
            <span className="block w-full h-3 bg-[#EDF1F5] rounded-r2 relative">
              <span
                style={{
                  width:
                    i < props.currentStep
                      ? '100%'
                      : ((+props.currentQuestion + 1) * 100) / +props.questionsLength + '%',
                }}
                className={`${i === props.currentStep ? 'bg-green' : ''} ${i < props.currentStep ? 'bg-green' : ''} block absolute top-0 left-0 h-full rounded-r2 content-[''] transition-all z-10`}
              ></span>
            </span>
          </li>,
        )

        if (isFirstStep) break
      } else {
        listItems.push(
          <li
            className={`${i <= props.currentStep ? ' before:bg-tickGreen' : 'before:bg-progress'} progress w-full px-8 h-1 relative after:bg-progress`}
            key={i}
          >
            <span className="block w-full h-1 bg-progress rounded-r2 relative">
              <span
                style={{
                  width:
                    i < props.currentStep
                      ? '100%'
                      : ((+props.currentQuestion + 1) * 100) / +props.questionsLength + '%',
                }}
                className={`${i === props.currentStep ? 'bg-green' : ''} ${i < props.currentStep ? 'bg-green' : ''} block absolute top-0 left-0 h-full rounded-r2 content-[''] transition-all z-10`}
              ></span>
            </span>
          </li>,
        )
      }
    }
    return listItems
  }
  return (
    <ul className={`${props.version === 2 ? 'w-[200px] mx-auto' : 'old-progress'} flex px-2`}>
      {renderList()}
    </ul>
  )
}
