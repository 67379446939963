import React from 'react'
import { Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface BMITooltipProps {
  bmi: number
  percent?: boolean
  answers: Answers
  unit: string
  betterme?: boolean
}
export function BMITooltipV2(props: BMITooltipProps) {
  const { t } = useTranslation()
  return (
    <div>
      {props.bmi >= 6 && props.bmi < 18.5 && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#E15234] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#E15234] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Your BMI')}: {props.bmi.toFixed(1)}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#E15234] text-xl3 text-center capitalize pt-2">
              {t('Underweight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                "Great job taking the first step! We'll create a program for you based on your BMI.",
              )}
            </p>
          </div>
        </div>
      )}
      {props.bmi >= 18.5 && props.bmi < 25 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Your BMI')}: {props.bmi.toFixed(1)}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#61CE75] text-xl3 text-center capitalize pt-2">
              {t('Normal')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                'You’re starting from a great place! Now we’ll use your BMI to create a program tailored to your needs.',
              )}
            </p>
          </div>
        </div>
      )}
      {props.bmi >= 25 && props.bmi < 30 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#FFAE35] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#FFAE35] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Your BMI')}: {props.bmi.toFixed(1)}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#FFAE35] text-xl3 text-center capitalize pt-2">
              {t('Overweight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                "You're off to a great start! We'll use your BMI to tailor a weight loss plan for you.",
              )}
            </p>
          </div>
        </div>
      )}
      {props.bmi >= 30 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#E15234] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#E15234] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Your BMI')}: {props.bmi.toFixed(1)}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#E15234] text-xl3 text-center capitalize pt-2">
              {t('Obese')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                "Losing a little can mean gaining a lot! We'll tailor your weight loss plan based on your BMI.",
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
