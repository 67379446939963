import React, { ChangeEvent, useEffect, useRef } from 'react'
import { Answer, Answers, CheckboxCardType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface CheckboxCardProps {
  attributes: CheckboxCardType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  step: number
  answers: Answers
}

export default function CheckboxCard(props: CheckboxCardProps) {
  const version = props.attributes?.version ?? 1
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const parent = e.target.closest('.steps') ?? document
    const reset: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[data-reset]')
    const allCheck: NodeListOf<HTMLInputElement> =
      parent.querySelectorAll('input[data-choosed-all]')
    if (e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: [e.target.value] })
      reset.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    } else if (!e.target.checked && !e.target.dataset.reset && !e.target.dataset.choosedAll) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      allCheck.forEach((element: HTMLInputElement) => {
        if (element.getAttribute('name') === e.target.getAttribute('name') && element.checked) {
          element.checked = false
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
      })
    }
    if (e.target.checked && e.target.dataset.reset) {
      const targetName = e.target.getAttribute('name')
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = element.getAttribute('name') === targetName && e.target === element
      })
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (!e.target.checked && e.target.dataset.reset) {
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
    }
    if (e.target.dataset.choosedAll && e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element && element.checked === false) {
          props.onAnswer({ [props.name]: [element.value] })
          element.checked = true
        }
      })
      e.target.checked = true
      props.onAnswer({ [props.name]: [e.target.value] })
    } else if (e.target.dataset.choosedAll && !e.target.checked) {
      const inputs: NodeListOf<HTMLInputElement> = parent.querySelectorAll('input[type="checkbox"]')
      inputs.forEach((element: HTMLInputElement) => {
        if (e.target !== element) {
          props.onAnswer({ [props.name]: ['remove-' + element.value] })
        }
        element.checked = false
      })
      props.onAnswer({ [props.name]: ['remove-' + e.target.value] })
      e.target.checked = false
    }
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    const inputs = document.querySelectorAll('input')
    if (choosedValue && typeof choosedValue === 'string') {
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
    if (choosedValue && typeof choosedValue === 'object') {
      choosedValue.forEach((element) => {
        inputs.forEach((input) => {
          if (input.value === element) {
            input.checked = true
          }
        })
      })
    }
  }, [])
  return (
    <div>
      <label
        className={`
        bg-white items-center checkbox font-semibold relative  m-0 rounded-xl  min-h-[74px] cursor-pointer  transition duration-300 font-girloy  after:absolute after:content=[''] after:w-5 after:h-5  after:border after:border-checkBorder after:bg-check after:right-3 after:rounded-md active:scale-95 
        ${version === 2 ? ' after:bottom-4 border-2 shadow-[3px_14px_20px_0px_rgb(42_97_48/12%)]' : ' after:bottom-6'}
        ${
          checkbox.current?.checked
            ? version === 2
              ? '!bg-checked border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat border-[#95DBAF] shadow-[0px_4px_11px_0px_rgba(191,245,204,0.8)]'
              : ' !bg-checked border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat'
            : ' border-white'
        }`}
      >
        {props.attributes?.img && (
          <span className="flex items-center w-full justify-center">
            <img
              className={
                version === 2
                  ? 'object-cover object-top h-[150px] w-full rounded-xl'
                  : 'w-auto max-w-full object-cover h-[138px] w-auto'
              }
              width={24}
              height={24}
              alt="Checkbox img"
              src={props.attributes?.img}
            />
          </span>
        )}
        <span
          className={`${version === 2 ? '' : 'h-16'} flex items-center leading-normal block p-3 pr-10 bg-white rounded-b-r12 text-base text-semibold`}
        >
          {props.attributes.text}
        </span>
        <input
          type="checkbox"
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
          name={props.name}
          value={props.attributes.hideText ? props.attributes.hideText : props.attributes.text}
          className="hidden"
          ref={checkbox}
          data-reset={props.attributes.resetCheckbox}
          data-choosed-all={props.attributes.allCheckbox}
        />
      </label>
    </div>
  )
}
