import { trackEmail, trackErrors } from '@/helpers/facebookPixelEvents'

const preCreateResponse = async (
  res: any,
  isUpdate: boolean,
  email: string,
  successSubmit: (id: string) => void,
  setError: (msg: string) => void,
) => {
  if (res.body?.error === '"email" must be a valid email') {
    setError('Email is not valid')
    trackErrors('mail_1', res?.body)
    return
  }

  if (isUpdate && res.status === 200) {
    const customerId = sessionStorage.getItem('customerId') as string
    successSubmit(customerId)
    trackEmail('mail_1', email)
    return
  }

  if (res.body?.id) {
    sessionStorage.setItem('customerId', res.body.id)
    localStorage.setItem('email', email)
    successSubmit(res.body.id)
    trackEmail('mail_1', email)
  } else {
    setError('oops, something went wrong')
    trackErrors('mail_1', res?.body)
  }
}

export default preCreateResponse
