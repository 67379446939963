import { useEffect, useState } from 'react'

const useCountdownTimer = (storageKey = 'endTimer', defaultDurationMs = 10 * 60 * 1000) => {
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [timeDifference, setTimeDifference] = useState<number>(0)

  useEffect(() => {
    let storedEndTime = sessionStorage.getItem(storageKey)
    if (!storedEndTime) {
      const newEndTime = new Date(new Date().getTime() + defaultDurationMs)
      storedEndTime = newEndTime.toISOString()
      sessionStorage.setItem(storageKey, storedEndTime)
    }
    setEndTime(new Date(storedEndTime))
  }, [storageKey, defaultDurationMs])

  useEffect(() => {
    if (!endTime) return
    const updateTimer = () => {
      const currentTime = new Date()
      const diff = endTime.getTime() - currentTime.getTime()
      setTimeDifference(diff > 0 ? diff : 0)
    }

    updateTimer()
    const intervalId = setInterval(updateTimer, 1000)
    return () => clearInterval(intervalId)
  }, [endTime])

  return timeDifference
}

export default useCountdownTimer
