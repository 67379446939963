import { getCountryCode } from '@/helpers/getCountryCode'
import { NavigateFunction } from 'react-router-dom'

const initAnswersDemo = async (navigate: NavigateFunction) => {
  const answers = await import('@/constants/answersDemo.json')

  localStorage.removeItem('visitTime')
  localStorage.removeItem('activePlan')
  localStorage.removeItem('email')

  sessionStorage.removeItem('customerId')
  sessionStorage.removeItem('paywallStatus')
  sessionStorage.removeItem('endTimer')
  sessionStorage.removeItem('paywallStatus')

  getCountryCode().then((res) => {
    sessionStorage.setItem('countryCode', res ? res.country : 'US')
    sessionStorage.setItem('postalCode', res ? res.postal : '90071')
    sessionStorage.setItem('ip', res ? res.ip : '')
  })

  sessionStorage.setItem('placeholderAge', '33')

  sessionStorage.setItem('answers', JSON.stringify(answers))
  sessionStorage.setItem('quizResult', JSON.stringify(answers))

  navigate('/4/1', { replace: true })
}

export default initAnswersDemo
