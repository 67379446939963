import { useState, useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import { getBucketValue } from '@/hooks/featureFlag/utils/hash'

interface FlagConfig {
  enabled: boolean
  bucketingSalt: string
  rolloutWeights: Record<string, number>
  rolloutPercentage: number
  variants: Array<{ key: string; name: string }>
}

export interface FlagVariant {
  key: string
  name: string
}

export interface UseFeatureFlagResult {
  variant: FlagVariant
  loading: boolean
}

const useFeatureFlag = (flagName: string, defaultVariant = 'default'): UseFeatureFlagResult => {
  const [variant, setVariant] = useState<FlagVariant | null>(null)
  const [loading, setLoading] = useState(true)
  const defaultFeature: FlagVariant = { key: defaultVariant, name: defaultVariant }

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null

    async function fetchFlagConfig(amplitudeId: number) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/amplitude/experiment/flags?flag=${flagName}`,
        )
        const config: FlagConfig = await response.json()

        if (!config.enabled) {
          setVariant(defaultFeature)
          setLoading(false)
          return
        }

        const { bucketingSalt, rolloutWeights, rolloutPercentage } = config
        const bucketValue = getBucketValue(amplitudeId, bucketingSalt)

        if (bucketValue > rolloutPercentage) {
          setVariant(defaultFeature)
          setLoading(false)
          return
        }

        const totalWeight = Object.values(rolloutWeights).reduce((a, b) => a + b, 0)
        const normalizedValue = (bucketValue / rolloutPercentage) * totalWeight
        let cumulative = 0
        let assignedVariantKey = defaultVariant

        for (const [key, weight] of Object.entries(rolloutWeights) as [string, number][]) {
          cumulative += weight
          if (normalizedValue <= cumulative) {
            assignedVariantKey = key
            break
          }
        }

        const foundVariant = config.variants.find((v) => v.key === assignedVariantKey)
        setVariant(foundVariant || defaultFeature)
      } catch (error) {
        console.error('Error fetching feature flag:', error)
        setVariant(defaultFeature)
      } finally {
        setLoading(false)
      }
    }

    const checkAmplitudeId = () => {
      const amplitudeId = amplitude.getSessionId()
      if (amplitudeId) {
        if (interval) {
          clearInterval(interval)
        }
        fetchFlagConfig(amplitudeId)
      }
    }

    checkAmplitudeId()

    if (!amplitude.getSessionId()) {
      interval = setInterval(checkAmplitudeId, 100)
    }

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [flagName, defaultVariant])

  return { variant: variant ?? defaultFeature, loading }
}

export default useFeatureFlag
