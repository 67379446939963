import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import initAnswersDemo from '@/helpers/initAnswersDemo'
import useAddUtmIfDev from '@/hooks/useAddUtmIfDev'

export const DemoQuiz = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const test = searchParams.get('test')
  useAddUtmIfDev()

  useEffect(() => {
    if (test === 'demo') initAnswersDemo(navigate)
  }, [test])

  return <div>Demo Quiz Init</div>
}
