import React, { useEffect, useState } from 'react'
import danger from '@/assets/images/dange.svg'
import warning from '@/assets/images/warning.svg'
import good from '@/assets/images/good.svg'
import { getValue } from '@/helpers/getObjectValue'
import { Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface BMITooltipProps {
  bmi: number
  percent?: boolean
  answers: Answers
  unit: string
  betterme?: boolean
}
export function BMITooltip(props: BMITooltipProps) {
  const [low, setLow] = useState(0)
  const [hight, setHight] = useState(0)
  const [unit, setUnit] = useState('kg')
  const { t } = useTranslation()
  useEffect(() => {
    if (props.answers) {
      const heightProps = getValue('human-height', props.answers)
      if (heightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT'
            ? (+heightProps[0] * 30.48) / 100
            : +heightProps[0] / 100
        if (props.unit === 'lbs') {
          setHight(25 * (tall * tall) * 2.2)
          setLow(18.5 * (tall * tall) * 2.2)
          setUnit('lbs')
        } else {
          setHight(25 * (tall * tall))
          setLow(18.5 * (tall * tall))
          setUnit('kg')
        }
      }
    }
  }, [props.bmi])
  return (
    <div>
      {props.bmi >= 6 && props.bmi < 18.5 && props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} border border-dangerStroke bg-danger rounded-r12`}
        >
          <div className="flex items-start">
            <img className="mr-4 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <div>
              <h6 className={`${props.betterme ? 'text-md' : 'text-xl2'}`}>
                <strong className="font-extrabold">{t('Uh-oh! Low weight alert!')}</strong>
              </h6>
              <p className="mt-3 text-base leading-snug">
                {t('A normal weight range for your height is between')} {Math.ceil(low)} {unit}{' '}
                {t('and')} {Math.round(hight)} {unit}. {t('Any weight below')} {Math.ceil(low)}{' '}
                {unit}{' '}
                {t(
                  'is classified as underweight and is not recommended by World Health Organization.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.bmi >= 6 && props.bmi < 18.5 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-dangerStroke bg-danger  rounded-r12`}
        >
          <div className="flex items-start">
            <img className="mr-4 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <div>
              <h6 className={`${props.betterme ? 'text-md' : 'text-xl2'}`}>
                {t('Your BMI is')} {props.bmi.toFixed(1)} – {t('You`re in the')} <br />
                <strong className="font-extrabold">{t('underweight range')}</strong>
              </h6>
              <p className="mt-3 text-base leading-snug">
                {t(
                  'You have some work ahead of you, but it`s great that you`re taking this first step. We`ll use your BMI to create the weight loss program just for you.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.bmi >= 18.5 && props.bmi < 25 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-goodStroke bg-good  rounded-r12`}
        >
          <div className="flex items-start">
            <img className="mr-4 min-w-6" src={good} width="24" height="24" alt="Height icon" />
            <div>
              <h6 className={`${props.betterme ? 'text-md' : 'text-xl2'}`}>
                {t('Your BMI is')} {props.bmi.toFixed(1)} – {t('You`re in the')} <br />
                <strong className="font-extrabold">{t('healthy weight range')}</strong>
              </h6>
              <p className="mt-3 text-base leading-snug">
                {t(
                  'You`re starting from a great place! Now we`ll use your BMI to create a program tailored to your needs.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.bmi >= 25 && props.bmi < 30 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-warningStroke bg-warning  rounded-r12`}
        >
          <div className="flex items-start">
            <img className="mr-4 min-w-6" src={warning} width="24" height="24" alt="Height icon" />
            <div>
              <h6 className={`${props.betterme ? 'text-md' : 'text-xl2'}`}>
                {t('Your BMI is')} {props.bmi.toFixed(1)} {t('You`re in the')} <br />
                <strong className="font-extrabold">{t('overweight range')}</strong>
              </h6>
              <p className="mt-3 text-base leading-snug">
                {t(
                  'You have some work ahead of you, but it`s great that you`re taking this first step. We`ll use your BMI to create the weight loss program just for you.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
      {props.bmi >= 30 && !props?.percent && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'}  border border-dangerStroke bg-danger  rounded-r12`}
        >
          <div className="flex items-start">
            <img className="mr-4 min-w-6" src={danger} width="24" height="24" alt="Height icon" />
            <div>
              <h6 className={`${props.betterme ? 'text-md' : 'text-xl2'}`}>
                {t('Your BMI is')} {props.bmi.toFixed(1)} – {t('You`re in the')} <br />
                <strong className="font-extrabold">{t('obese range')}</strong>
              </h6>
              <p className="mt-3 leading-snug text-base">
                {t(
                  'There`s a lot you could gain by losing a little weight. We`ll use your BMI to create the weight loss program you need.',
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
