import { BaseHttpServices } from '@/services/base-http-services'
import { getPaymentPlansApiErrorMessage } from '@/app/api/payment-plan-api/payment-plan-api-error'

interface PaymentPlanApiInterface {
  preCreate: (type?: string) => Promise<any>
}

export class PreCreateApiService implements PaymentPlanApiInterface {
  private readonly http: BaseHttpServices

  constructor(httpService: BaseHttpServices) {
    this.http = httpService
    this.http.getErrorMessage = (message) => getPaymentPlansApiErrorMessage(message)
  }

  preCreate = async (data: any) => {
    return await this.http.post('/account/web/pre-create', data)
  }

  preUpdate = async (data: any) => {
    return await this.http.patch('/account/web/pre-create', data)
  }
}

export const PreCreateApi = new PreCreateApiService(new BaseHttpServices())
