import React from 'react'
import { useTranslation } from 'react-i18next'

interface GetMovingProps {
  lose: string
  percent: number
  betterme?: boolean
}
export function GetMovingV2(props: GetMovingProps) {
  const { t } = useTranslation()
  return (
    <div className={'w-full'}>
      {props.percent >= 20 && props.lose === 'lose' && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t("It's worth")}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center lowercase pt-2">
              <strong className="font-extrabold text-[#61CE75]">
                {t('lose')} {Math.round(props.percent)}%
              </strong>{' '}
              {t('of your weight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                'Mayo Clinic study: Losing over 20% of body weight improves metabolic health more than losing 5-10%.',
              )}
            </p>
          </div>
        </div>
      )}
      {props.percent >= 10 && props.percent < 20 && props.lose === 'lose' && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Gain health benefits ')}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center lowercase pt-2">
              <strong className="font-extrabold text-[#61CE75]">
                {t('lose')} {Math.round(props.percent)}%
              </strong>{' '}
              {t('of your weight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t('Losing 10%+ body weight cuts risks of obesity-related problems, studies say.')}
            </p>
          </div>
        </div>
      )}
      {props.percent >= 5 && props.percent < 10 && props.lose === 'lose' && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Realistic goal')}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center lowercase pt-2">
              <strong className="font-extrabold text-[#61CE75]">
                {t('lose')} {Math.round(props.percent)}%
              </strong>{' '}
              {t('of your weight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                'CDC advises that shedding 4 kg monthly is safe, healthy, and can lower risks of chronic ailments like type 2 diabetes and heart disease.',
              )}
            </p>
          </div>
        </div>
      )}
      {props.percent < 5 && props.lose === 'lose' && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Realistic goal')}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center lowercase pt-2">
              <strong className="font-extrabold text-[#61CE75]">
                {t('lose')} {Math.round(props.percent)}%
              </strong>{' '}
              {t('of your weight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                'CDC advises that shedding 4 kg monthly is safe, healthy, and can lower risks of chronic ailments like type 2 diabetes and heart disease.',
              )}
            </p>
          </div>
        </div>
      )}
      {props.percent >= 0 && props.lose === 'gain' && (
        <div
          className={`${props.betterme ? 'p-6 mt-4' : 'p-4 mt-8'} rounded-2xl border border-[#61CE75] bg-white]`}
        >
          <div className="relative">
            <div className="absolute w-full top-[-30px] text-center">
              <span className="bg-[#61CE75] font-girloy text-base text-white font-bold p-2 rounded-[10px]">
                {t('Get moving')}
              </span>
            </div>
            <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center lowercase pt-2">
              <strong className="font-extrabold text-[#61CE75]">
                {t('gain')} {Math.round(props.percent)}%
              </strong>{' '}
              {t('of your weight')}
            </h6>
            <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
              {t(
                'A University of Utah study found that just 5 minutes of daily exercise can maintain fitness, boost energy, and improve sleep.',
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  )
}
