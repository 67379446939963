export function mapDietType(selectedDiet: string): string {
  const dietMapping: Record<string, string> = {
    Traditional: 'common',
    Keto: 'keto',
    Paleo: 'paleo',
    Vegetarian: 'vegetarian',
    'Vegan (Plant Diet)': 'vegan',
    'Lactose Free': 'vegetarian',
  }

  return dietMapping[selectedDiet] || 'common'
}
