import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Quiz from '@/components/Quiz/Quiz'
import Paywall from '@/components/Paywall/Paywall'
import PaywallV2 from '@/components/Paywall/PaywallV2'
import i18n from './i18n'
import CreateAccount from '@/components/CreateAccount/CreateAccount'
import { FAQ } from '@/components/pages/FAQ/FAQ'
import { PrivacyPolicy } from '@/components/pages/PrivacyPolicy/PrivacyPolicy'
import { MoneyBackPolicy } from '@/components/pages/MoneyBackPolicy/MoneyBackPolicy'
import { OfferPolicy } from '@/components/pages/OfferPolicy/OfferPolicy'
import ThankYou from '@/components/ThankYou/ThankYou'
import { ConfirmEmail } from '@/components/ConfirmEmail'
import { Profitwell } from '@/components/Profitwell'
import { TestError } from '@/components/pages/TestError/TestError'
import { StartScreen } from '@/components/QuestionsUI/StartScreen'
import { StartScreenV6 } from '@/components/QuestionsUI/StartScreenV6'
import { DemoQuiz } from '@/components/pages/DemoQuiz/DemoQuiz'
import useFeatureFlag from '@/hooks/featureFlag/useFeatureFlag'
import { trackFeatureAnalytic } from '@/helpers/facebookPixelEvents'
import { HOMEPAGE_PATH, QUIZ_VERSION, QUIZ_NAME } from './constants/variables'

const queryParams = new URLSearchParams(window.location.search)
sessionStorage.setItem('quizVersion', 'true')

const App = () => {
  const validQuizVersions = ['3', '6', '7']
  const flagResult = validQuizVersions.includes(QUIZ_VERSION)
    ? useFeatureFlag('split_v3_v6', 'v3')
    : { variant: { key: 'v3', name: 'v3' }, loading: false }

  const { variant, loading: loadingFeature } = flagResult

  useEffect(() => {
    if (!loadingFeature && validQuizVersions.includes(QUIZ_VERSION)) {
      const tracked = sessionStorage.getItem('tracked-split_v3_v6')
      if (!tracked) {
        trackFeatureAnalytic({
          variant,
          type: 'split_v3_v6',
          page: 'home',
          quiz: { v: QUIZ_VERSION, name: QUIZ_NAME },
        })
        sessionStorage.setItem('tracked-split_v3_v6', 'true')
      }
    }

    if (variant.key === 'v6') {
      document.body.classList.add('design-v2')
    }
  }, [loadingFeature, variant])

  if (loadingFeature) {
    return <></>
  }

  return (
    <Router basename={HOMEPAGE_PATH}>
      <Routes>
        <Route
          path="/:currentStepString/:currentQuestionString"
          element={
            variant.key === 'v6' ? (
              <Quiz
                startPath="/start"
                mainPath="/"
                nextPath="/0/0"
                lang={i18n.language}
                quizFile="quiz_v6.json"
                version={2}
              />
            ) : (
              <Quiz
                startPath="/start"
                mainPath="/"
                nextPath="/0/0"
                lang={i18n.language}
                quizFile="quiz.json"
                version={1}
              />
            )
          }
        />
        <Route
          path="/start/"
          element={
            variant.key === 'v6' ? (
              <StartScreenV6 nextPath="/0/0" />
            ) : (
              <StartScreen nextPath="/0/0" />
            )
          }
        />
        <Route path="/" element={<Navigate to={`/start/?${queryParams.toString()}`} />} />
        <Route
          path="/paywall/"
          element={
            variant.key === 'v6' ? (
              <PaywallV2
                paymentMethod="stripe"
                paywallFile={
                  process.env.REACT_APP_MODE !== 'dev' ? 'paywall_v6.json' : 'paywall-dev_v6.json'
                }
                lang={i18n.language}
              />
            ) : (
              <Paywall
                paymentMethod="stripe"
                paywallFile={
                  process.env.REACT_APP_MODE !== 'dev' ? 'paywall.json' : 'paywall-dev.json'
                }
                lang={i18n.language}
              />
            )
          }
        />
        <Route path="/confirm-email/" element={<ConfirmEmail />} />
        <Route path="/profitwell/" element={<Profitwell />} />
        <Route path="/register/" element={<CreateAccount />} />
        <Route path="/faq/" element={<FAQ />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicy />} />
        <Route path="/money-back/" element={<MoneyBackPolicy />} />
        <Route path="/offer-policy/" element={<OfferPolicy />} />
        <Route path="/thank-you/" element={<ThankYou />} />
        <Route path="/test-register/" element={<CreateAccount testMode={true} />} />
        <Route path="/test-error" element={<TestError />} />
        <Route path="/demo-quiz" element={<DemoQuiz />} />
      </Routes>
    </Router>
  )
}

export default App
