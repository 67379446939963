import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers } from '@/types/types'
import React, { useEffect, useState } from 'react'
import Image from '../Image/Image'
import BaseGraphuUp from '@/assets/images/base-graph-up.png'
import BaseGraphuUpWebp from '@/assets/images/base-graph-up.webp'
import BaseGraphuDown from '@/assets/images/base-graph-down.png'
import BaseGraphuUpDownp from '@/assets/images/base-graph-down.webp'

interface GraphProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
  version?: number
}

export const PurchaseWithName: React.FC<GraphProps> = (props) => {
  const name = getValue('your-name', props.answers)
  const [loadAnimation, setLoadAnimation] = useState(false)
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const condition =
    currentWeight && goalWeight && parseInt(currentWeight[0]) < parseInt(goalWeight[0])

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>
    if (props.active) {
      timeoutId = setTimeout(() => {
        setLoadAnimation(true)
      }, 500)
    } else {
      setLoadAnimation(false)
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [props.active])

  return (
    <>
      {props.version === 2 ? (
        <>
          <p className="mt-4 text-center text-[#17181D] font-bold text-xl4 font-girloy capitalize">
            {name},
          </p>
          <p className="mb-5 text-center text-[#5F626F] text-xl2 font-girloy">
            your 4-week Wall
            <br />
            Pilates Workout Plan is ready!
          </p>
        </>
      ) : (
        <h2 className="text-xl35 -mt-4 font-girloy">
          <mark>{name},</mark>
          <br />
          your 4-week Wall Pilates Workout Plan is ready!
        </h2>
      )}

      <Image
        className={`${loadAnimation ? 'active-betterme-graph' : 'opacity-0'} w-[300px] mx-auto`}
        src={condition ? BaseGraphuDown : BaseGraphuUp}
        webp={condition ? BaseGraphuUpDownp : BaseGraphuUpWebp}
        alt="graph"
      />

      {props.version !== 2 && (
        <p className="text-base text-[#727272] font-semibold text-center mt-4">
          This chart is for illustrative purposes only
        </p>
      )}
    </>
  )
}
