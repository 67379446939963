const initCurrencyCode = (country) => {
  const currencyMap = {
    EUR: [
      'AT',
      'BE',
      'HR',
      'CY',
      'EE',
      'FI',
      'FR',
      'DE',
      'GR',
      'IE',
      'IT',
      'LV',
      'LT',
      'LU',
      'MT',
      'NL',
      'PT',
      'SK',
      'SI',
      'ES',
      'PL',
    ],
    CAD: ['CA'],
    GBP: ['GB'],
  }

  let currencyCode = 'USD'
  let currencySymbol = '$'

  for (const [currency, countries] of Object.entries(currencyMap)) {
    if (countries.includes(country)) {
      currencyCode = currency
      switch (currency) {
        case 'EUR':
          currencySymbol = '€'
          break
        case 'CAD':
          currencySymbol = 'C$'
          break
        case 'GBP':
          currencySymbol = '£'
          break
        default:
          currencySymbol = '$'
      }
      break
    }
  }

  sessionStorage.setItem('currencyCode', currencyCode)
  sessionStorage.setItem('currencySymbol', currencySymbol)
}

export const replayItem = (code: string, item: string, properties: any) => {
  return properties[`${item.replace('_usd', '')}_${code.toLowerCase()}`]
    ? properties[`${item.replace('_usd', '')}_${code.toLowerCase()}`]
    : properties[item]
}

export default initCurrencyCode
