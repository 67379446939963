import React, { useEffect, useState } from 'react'
import { Answer, Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'
import { ProgressStep } from './ProgressStep'
import { getValue } from '@/helpers/getObjectValue'
import RadioButton from '@/components/QuestionsUI/RadioButton'

interface ProgressProps {
  active: boolean
  src: string
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
  setHideDelayBtn: (status: boolean) => void
  version?: number
}

export default function ProgressSteps(props: ProgressProps) {
  const progress = getValue('progress-step', props.answers)
  const [isActive, setIsActive] = useState(false)
  const [isActive2, setIsActive2] = useState(false)
  const [isActive3, setIsActive3] = useState(false)
  const [animationData, setAnimationData] = useState<any>(undefined)
  const [progressStep, setProgressStep] = useState(progress ? 2 : 1)
  const [showPopup, seShowPopup] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    if (props?.src && !animationData) {
      fetch(props?.src)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.error('Error fetching animation data:', error))
    }
  }, [])
  useEffect(() => {
    if (props.active && !progress) {
      setProgressStep(1)
      seShowPopup(false)
      setIsActive(true)
      setTimeout(() => {
        seShowPopup(true)
      }, 3000)
    }
  }, [props.active])
  const closePopup = () => {
    if (!progress) {
      props.setHideDelayBtn(true)
      setProgressStep((progressStep ?? 1) + 1)
      seShowPopup(false)
      if (progressStep === 1) {
        setIsActive2(true)
        setTimeout(() => {
          seShowPopup(true)
        }, 3000)
      }
      if (progressStep === 2) {
        setIsActive3(true)
        setTimeout(() => {
          props.onAnswer({ 'progress-step': ['progress-step'] })
          props.setHideDelayBtn(false)
        }, 3000)
      }
    }
  }
  return (
    <div>
      <div
        className={
          props.version !== 2
            ? 'progress-bar p-4 bg-[#FAFAFA] rounded-r12 border border-borderInput'
            : ''
        }
      >
        <ProgressStep
          answers={props.answers}
          title="Lifestyle & Activity"
          active={isActive}
          version={props.version}
        />
        <ProgressStep
          answers={props.answers}
          title="Fitness Level Analysis"
          active={isActive2}
          version={props.version}
        />
        <ProgressStep
          answers={props.answers}
          title="Body Analysis"
          active={isActive3}
          version={props.version}
        />

        {props.version === 2 && (
          <h3 className="text-base font-extrabold py-5 font-girloy text-center">
            {t('Trusted by 98,425 users!')}
          </h3>
        )}
      </div>
      {props.version === 2 ? (
        <>
          <div
            className={`${showPopup && progressStep === 1 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-[#5858587a] bottom-0 left-0 flex items-end justify-center w-full h-screen p-0`}
          >
            <div className="bg-[#FBFFFC] text-center rounded-r30 rounded-b-none pt-8 pb-3 px-6 shadow-popup w-full max-w-[500px]">
              <h2 className="text-xl35 font-girloy font-bold p-0 m-0 mb-5">
                {t('Do you quit when exercises')}
                <br />
                {t('get tough or dull?')}
              </h2>
              <RadioButton
                onAnswer={closePopup}
                name={'Do you quit when exercises get tough or dull?'}
                attributes={{
                  text: 'Yes',
                  bgImg: {
                    src: '/img/quiz-wallpilates-v6/0-7-1.jpg',
                    srcWebp: '/img/quiz-wallpilates-v6/0-7-1.webp',
                  },
                  bigSize: false,
                  withTick: true,
                  listSelect: true,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={() => {}}
                answers={props.answers}
              />
              <RadioButton
                onAnswer={closePopup}
                name={'Do you quit when exercises get tough or dull?'}
                attributes={{
                  text: 'No',
                  bgImg: {
                    src: '/img/quiz-wallpilates-v6/0-7-2.jpg',
                    srcWebp: '/img/quiz-wallpilates-v6/0-7-2.webp',
                  },
                  bigSize: false,
                  withTick: true,
                  listSelect: true,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={() => {}}
                answers={props.answers}
              />
            </div>
          </div>
          <div
            className={`${showPopup && progressStep === 2 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-[#5858587a] bottom-0 left-0 flex items-end justify-center w-full h-screen p-0`}
          >
            <div className="bg-[#FBFFFC] text-center rounded-r30 rounded-b-none pt-8 pb-3 px-6 shadow-popup w-full max-w-[500px]">
              <h2 className="text-xl35 font-girloy font-bold p-0 m-0 mb-5">
                {t('Are you a morning lark or')}
                <br />
                {t('night owl?')}
              </h2>
              <RadioButton
                onAnswer={closePopup}
                name={'Are you a morning lark or night owl?'}
                attributes={{
                  text: 'Morning Lark',
                  bgImg: {
                    src: '/img/quiz-wallpilates-v6/sun.jpg',
                    srcWebp: '/img/quiz-wallpilates-v6/sun.webp',
                  },
                  bigSize: false,
                  withTick: true,
                  listSelect: true,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={() => {}}
                answers={props.answers}
              />
              <RadioButton
                onAnswer={closePopup}
                name={'Do you quit when exercises get tough or dull?'}
                attributes={{
                  text: 'Night Owl',
                  bgImg: {
                    src: '/img/quiz-wallpilates-v6/moon.jpg',
                    srcWebp: '/img/quiz-wallpilates-v6/moon.webp',
                  },
                  bigSize: false,
                  withTick: true,
                  listSelect: true,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={() => {}}
                answers={props.answers}
              />
              <RadioButton
                onAnswer={closePopup}
                name={'Do you quit when exercises get tough or dull?'}
                attributes={{
                  text: "I'm not sure",
                  bgImg: {
                    src: '/img/quiz-wallpilates-v6/question.jpg',
                    srcWebp: '/img/quiz-wallpilates-v6/question.webp',
                  },
                  bigSize: false,
                  withTick: true,
                  listSelect: true,
                }}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                triggerNextStep={() => {}}
                answers={props.answers}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`${showPopup && progressStep === 1 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-lightBlue top-0 left-0 flex items-center justify-center w-full h-screen backdrop-blur-sm p-9`}
          >
            <div className="bg-white text-center border-2 border-borderInput rounded-r12 pt-14 pb-10 px-6 shadow-popup">
              <h2 className="text-xl3 font-extrabold p-0 m-0 mb-10">
                {t('Do you')}
                <mark> {t('give up')} </mark>
                {t('when exercises become too')}
                <mark> {t('hard or boring')}?</mark>
              </h2>
              <div
                onClick={closePopup}
                className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer"
              >
                {t('YES')}
              </div>
              <div
                onClick={closePopup}
                className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
              >
                {t('NO')}
              </div>
            </div>
          </div>
          <div
            className={`${showPopup && progressStep === 2 ? 'opacity-100 visible' : 'opacity-0 invisible'} transition-all fixed z-50 bg-lightBlue  top-0 left-0 flex  items-center justify-center w-full h-screen backdrop-blur-sm p-9`}
          >
            <div className="bg-white text-center border-2 border-borderInput rounded-r12 pt-14 pb-10 px-6 shadow-popup">
              <h2 className="text-xl3 font-extrabold p-0 m-0 mb-10">
                {t('Are you a')}
                <mark> {t('morning lark')} </mark>
                {t('or')}
                <mark> {t('night owl?')}</mark>
              </h2>
              <div
                onClick={closePopup}
                className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer"
              >
                {t('Morning Lark')}
              </div>
              <div
                onClick={closePopup}
                className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
              >
                {t('Night Owl')}
              </div>
              <div
                onClick={closePopup}
                className="mx-4 text-xl2 flex items-center justify-center bg-lightGray rounded-r12 border border-borderInput p-4 font-bold active:scale-95 transition-all cursor-pointer mt-3"
              >
                {t("I'm not sure")}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
