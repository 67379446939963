import { getValue } from '@/helpers/getObjectValue'
import { Answer, Answers, LifestyleActiveType } from '@/types/types'
import Lottie from 'lottie-react'
import React, { useEffect, useState } from 'react'
// import animationSwipe from '@/assets/images/swipe.json'

interface LifestyleActiveProps {
  attributes: [LifestyleActiveType]
  onAnswer: (answer: Answer, step?: number) => void
  name: string
  isActive: boolean
  answers: Answers
}

export function LifestyleActiveV2(props: LifestyleActiveProps) {
  const [value, setValue] = useState(props.attributes[0])
  const [animationData, setAnimationData] = useState([''])
  async function fetchAnimationData() {
    try {
      const animationDataArray: any = []
      for (const item of props.attributes) {
        const response = await fetch(item.src)
        const data = await response.json()
        animationDataArray.push(data)
      }
      return animationDataArray
    } catch (error) {
      console.error('Error fetching animation data:', error)
    }
  }
  useEffect(() => {
    fetchAnimationData().then((res) => {
      if (res) {
        setAnimationData(res)
      }
    })
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      // eslint-disable-next-line array-callback-return
      props.attributes.map((item) => {
        if (item.name === choosedValue) {
          setValue(item)
        }
      })
    }
  }, [])
  useEffect(() => {
    if (props.isActive) {
      props.onAnswer({ [props.name]: [value.name + ''] })
    }
  }, [value])
  const touchMoveFun = (e: any) => {
    const swipeVal = +((e.changedTouches[0].pageX * 3) / window.innerWidth - 0.5).toFixed(0)
    setValue(props.attributes[Math.abs(swipeVal)])
  }

  return (
    <div onTouchMove={touchMoveFun} onTouchStart={touchMoveFun}>
      <div className="images relative pointer-events-none">
        {props.attributes.map(
          (item, index) =>
            item.name === value.name && (
              <div key={index} className="sm:mb-20 mb-6">
                <div className="mx-auto w-[280px] flex items-center justify-center">
                  <Lottie className="w-auto" animationData={animationData[index]} />
                </div>
                <h2
                  className={`text-xl35 font-bold text-center m-0 p-0 ${value.name === 'low' ? 'text-[#E15234]' : value.name === 'medium' ? 'text-[#FFAE35]' : value.name === 'high' ? 'text-[#61CE75]' : 'text-[#8C8F9D]'}`}
                >
                  {item.title}
                </h2>
                <p className="font-semibold text-dark opacity-65 text-center ">{item.text}</p>
              </div>
            ),
        )}
      </div>
      <div className="flex items-center justify-between mt-4 mb-8 relative z-10">
        {props.attributes.map((item, index) => (
          <p
            className={`text-base2 font-bold lifestyle capitalize font-girloy ${value.index === item.index ? (value.name === 'low' ? 'text-[#E15234]' : value.name === 'medium' ? 'text-[#FFAE35]' : value.name === 'high' ? 'text-[#61CE75]' : 'text-[#8C8F9D]') : 'text-[#8C8F9D]'}`}
            key={index}
          >
            {item.name}
          </p>
        ))}
      </div>
      <div className={`relative h-4 bg-progress rounded-lg range-line-v2 ${value.name}`}>
        <input
          className="input-range relative z-20"
          type="range"
          value={value.index}
          step={1}
          max={props.attributes?.length - 1}
          onChange={(e) => setValue(props.attributes[+e.target.value])}
        />

        <div className="w-full">
          {props.attributes.map((_, index) => (
            <span
              key={index}
              className="block w-3 h-3 rounded-full bg-dots border-white absolute top-1/2 -translate-y-1/2 z-10 border-0 bg-transparent"
            />
          ))}
        </div>
      </div>
    </div>
  )
}
