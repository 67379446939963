import React from 'react'
import { ReviewType } from '@/types/types'
import Image from '@/components/Image/Image'
import stars from '@/assets/images/stars.svg'

export default function Review(props: ReviewType) {
  return (
    <div
      className={`rounded-r12 overflow-hidden border border-borderInput ${props.version === 2 ? 'shadow-[0px_4px_11px_0px_rgba(215,229,218,0.8)] border-0 mx-2' : ''}`}
    >
      {props.mainTitle.length > 0 && (
        <h3
          className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold  text-center mb-3 mt-5"
          dangerouslySetInnerHTML={{ __html: props.mainTitle }}
        ></h3>
      )}
      <div className="relative w-full h-44">
        {props.src && !props.srcWebp && (
          <Image
            className="absolute w-full h-full top-0 left-0 object-cover"
            width="358"
            height="178"
            src={props.src}
            alt="reviw image"
          />
        )}
        {props.srcWebp && (
          <Image
            className="absolute w-full h-full top-0 left-0 object-cover"
            width="358"
            height="178"
            src={props.src}
            webp={props.srcWebp}
            alt="reviw image"
          />
        )}
        <h3
          className={`absolute z-10 bottom-0 rounded-ss-r20 right-0 font-bold text-white leading-tight ${props.version === 2 ? 'text-md py-2 px-5 bg-[#61CE75]' : 'text-base py-1 px-3 bg-green'}`}
        >
          {props.title}
        </h3>
      </div>
      <div className="py-3 px-5">
        <div className="flex items-center justify-between mb-3">
          <Image
            className=" max-w-full w-auto block"
            width={106}
            height={20}
            alt="Stars"
            src={stars}
          />
          <p
            className={`${props.version === 2 ? 'text-base text-[#5F626F] font-girloy font-semibold' : 'text-xs3 font-medium text-dark opacity-65'} leading-4`}
          >
            {props.name}
          </p>
        </div>
        <blockquote
          className={`${props.trustpilot || props.version === 2 ? 'text-left text-[#17181D] font-girloy text-base font-semibold' : 'text-center text-dark font-medium text-md'}`}
        >
          {props.quote}
        </blockquote>
      </div>
    </div>
  )
}
