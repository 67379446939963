import React, { useMemo } from 'react'
import { PlanHubspot } from '@/types/types'
import { formattedPrice } from '@/helpers/formatPrice'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'
import { replayItem } from '@/helpers/initCurrencyCode'

import arrow from '@/assets/images/arrow-right.svg'
import vector from '@/assets/images/vector.svg'
import vectorV2 from '@/assets/images/vectorV2.svg'
import vectorV2Active from '@/assets/images/vectorV2_active.svg'
import tick from '@/assets/scss/tick.svg'
import tickGreen from '@/assets/scss/tickGreen.svg'

interface PlansHubspotProps {
  descriptionTrial: string
  handleCheckout: () => void
  paymentMethod: string
  activePlan: number
  isDiscountPrice: boolean
  handlePlan: (index: number) => void
  plans: PlanHubspot[]
  version?: number
}

const isPlanHubspot = (plan: PlanHubspot): plan is PlanHubspot => {
  return 'properties' in plan
}

export function PlansHubspot(props: PlansHubspotProps) {
  const { t } = useTranslation()

  const currencyCode = useMemo(() => sessionStorage.getItem('currencyCode') || 'USD', [])
  const currencySymbol = useMemo(() => sessionStorage.getItem('currencySymbol') || '$', [])

  const activePlan = props.plans[props.activePlan]

  const planProperties = useMemo(() => {
    if (!isPlanHubspot(activePlan)) {
      return undefined
    }
    if (activePlan.properties.periodname === 'day') {
      const foundPlan = props.plans.find((plan: PlanHubspot) => {
        return (
          isPlanHubspot(plan) &&
          plan.properties.periodname === 'month' &&
          plan.properties.periodcount === '1'
        )
      })
      return foundPlan?.properties
    }
    return activePlan.properties
  }, [activePlan, props.plans])

  const handleCheckout = () => {
    localStorage.setItem('activePlan', JSON.stringify(activePlan))
    props.handleCheckout()
  }

  const formatPeriod = (period: any): string => {
    const periodCount = parseInt(period.periodcount, 10)
    if (period.periodname === 'day' && periodCount < 30) {
      return '4 weeks'
    }
    if (period.periodname === 'month') {
      return periodCount === 1 ? '4 weeks' : `${period.periodcount} months`
    }
    return ''
  }

  const renderTextInstruction = (
    currencySymbol: string,
    price: string | number,
    period: any,
  ): string => {
    const formattedPeriod = formatPeriod(period)
    return `Without cancellation, before the selected discounted intro plan ends, I accept that Fit4me will automatically charge ${currencySymbol}${price} every ${formattedPeriod} until I cancel. Cancel via support@fit4me-life.com.`
  }

  return (
    <div className="plans ">
      {props.plans.map((el, index) => {
        return props.version === 2 ? (
          <div className="relative mb-4" key={el.properties.label_name}>
            <div
              onClick={() => {
                props.handlePlan(index)
              }}
              className={`${index === props.activePlan ? 'active bg-white before:border-green2 after:opacity-100' : 'after:opacity-0'}  px-2.5 py-2.5 plan-item overflow-hidden mt-2  rounded-2xl  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-2xl before:transition-all  before:border-silverBorderV2 before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-2xl after:transition-all  after:border-green3 after:border-[3px] active:scale-95`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center pt-2 pl-1">
                  <div>
                    <span className="block font-bold text-xl12">{el.properties.label_name}</span>
                    <span className="block text-xs4 flex">
                      <s className="text-gray9 decoration-plan">
                        {' '}
                        {currencySymbol}
                        {formattedPrice(
                          parseFloat(replayItem(currencyCode, 'full_price', el.properties)) ?? 0,
                        )}
                      </s>
                      <Image className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                      <span className="font-semibold">
                        {currencySymbol}
                        {formattedPrice(
                          parseFloat(replayItem(currencyCode, 'hs_price_usd', el.properties)) ?? 0,
                        )}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="text-price flex items-center justify-end text-center relative min-w-price p-1.5">
                  <Image
                    className="absolute top-1/2 right-0 -translate-y-1/2 w-[171px] h-[63px]"
                    src={index === props.activePlan ? vectorV2Active : vectorV2}
                    alt="vector"
                  />
                  <div
                    className={
                      'text-price flex items-normal justify-end text-center relative min-w-price gap-2.5'
                    }
                  >
                    <span className="block relative  ml-3">
                      <s
                        className={`block text-gray9 text-xs4 font-regular leading-none ${index === props.activePlan ? 'text-white opacity-70' : ''}`}
                      >
                        {' '}
                        {currencySymbol}
                        {formattedPrice(
                          parseFloat(replayItem(currencyCode, 'full_price_per_day', el.properties)),
                        )}
                      </s>
                      <span
                        className={`text-xs4 text-black leading-none block font-medium ${index === props.activePlan ? 'text-white' : ''}`}
                      >
                        {t('per day')}
                      </span>
                    </span>
                    <span
                      className={`text-xl font-bold block font-girloy ${index === props.activePlan ? 'text-white' : ''}`}
                    >
                      {currencySymbol}
                      {formattedPrice(
                        parseFloat(replayItem(currencyCode, 'priceperday', el.properties)),
                      )}
                    </span>
                    <div
                      className={`${index === props.activePlan ? 'bg-lightGray border-green2 [&>img]:opacity-100' : 'bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder transition-all`}
                    >
                      <Image className="w-3" src={tickGreen} alt="tick" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {el.properties.mostpopular === 'true' && (
              <div
                className={
                  'bg-[#6DE187] text-center px-2 py-0.5 text-white font-bold text-xs2 transition-all absolute left-4 -top-1.5 rounded-[24px] bg-[linear-gradient(180deg,_#6DE187_0%,_#61CE75_100%)]'
                }
              >
                {t('MOST POPULAR')}
              </div>
            )}
          </div>
        ) : (
          <div
            key={el.id}
            onClick={() => {
              props.handlePlan(index)
            }}
            className={`${el.properties.mostpopular !== 'true' && index === props.activePlan ? 'bg-white' : 'bg-lightGray'} ${index === props.activePlan ? 'active bg-white before:border-green2 after:opacity-100' : 'after:opacity-0'}  p-1 plan-item overflow-hidden mt-2  rounded-lg  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-lg before:transition-all  before:border-checkBorder before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-lg after:transition-all  after:border-green2 after:border-2 active:scale-95`}
          >
            {el.properties.mostpopular === 'true' && (
              <div
                className={`${index === props.activePlan ? 'bg-green2' : 'bg-grayE'} text-center mb-1 -mt-1 -mx-6 px-4  text-white font-bold text-md transition-all`}
              >
                {t('MOST POPULAR')}
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center pl-2">
                <div
                  className={`${index === props.activePlan ? 'bg-green2 border-green2 [&>img]:opacity-100' : 'bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder mr-4 transition-all`}
                >
                  <Image className="w-3" src={tick} alt="tick" />
                </div>
                <div>
                  <span className="block font-bold text-xl12">{el.properties.label_name}</span>
                  <span className="block text-xs4 flex">
                    <s className="text-gray9 decoration-plan">
                      {' '}
                      {currencySymbol}
                      {formattedPrice(
                        parseFloat(replayItem(currencyCode, 'full_price', el.properties)) ?? 0,
                      )}
                    </s>
                    <Image className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                    <span className="font-semibold">
                      {currencySymbol}
                      {formattedPrice(
                        parseFloat(replayItem(currencyCode, 'hs_price_usd', el.properties)) ?? 0,
                      )}
                    </span>
                  </span>
                </div>
              </div>
              <div className="text-price pl-4 flex items-center justify-end text-center relative min-w-price ">
                <Image
                  className="absolute top-1/2 right-0 -translate-y-1/2 h-full"
                  src={vector}
                  alt="vector"
                />
                <span className="block py-2 px-3 relative w-full">
                  <s className=" block text-gray9 text-xs4 font-regular leading-none">
                    {' '}
                    {currencySymbol}
                    {formattedPrice(
                      parseFloat(replayItem(currencyCode, 'full_price_per_day', el.properties)),
                    )}
                  </s>
                  <span className="text-xl font-bold block">
                    {currencySymbol}
                    {formattedPrice(
                      parseFloat(replayItem(currencyCode, 'priceperday', el.properties)),
                    )}
                  </span>
                  <span className="text-xs4 text-black leading-none block font-medium">
                    {t('per day')}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )
      })}

      <p className="text-xs2 leading-4 mt-3 text-center">
        30 day{' '}
        <Link
          className={`${props.version === 2 ? 'text-[#17181D]' : 'text-green2'} underline`}
          to={'/money-back'}
        >
          money-back guarantee
        </Link>
      </p>
      <Button
        variant="plan"
        onClick={handleCheckout}
        className="mt-5  !p-4.5 cursor-pointer relative button-plan-animation"
      >
        {t('Get my plan')}
      </Button>
      <p className="mt-0 text-xs2  text-grayB text-center mt-6">
        {renderTextInstruction(
          currencySymbol,
          replayItem(currencyCode, 'full_price', planProperties),
          planProperties,
        )}
      </p>
    </div>
  )
}
