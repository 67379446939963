import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  COMPANY_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_NAME,
  QUIZ_NAME,
  QUIZ_VERSION,
} from '@/constants/variables'
import { Header } from '@/components/Header/Header'
import Layout from '@/components/Layout'
import { trackEvent, trackTimingComplete } from '@/helpers/facebookPixelEvents'
import { onFCP, onFID, onLCP, onTTFB } from 'web-vitals'
import { Metric } from 'web-vitals/src/types/base'
import ProgressiveImage from '@/components/ProgressiveImage/ProgressiveImage'
import { SessionStorageService } from '@/services/session-storage-service'
import { Answers } from '@/types/types'
import useAddUtmIfDev from '@/hooks/useAddUtmIfDev'
// import { StartLoading } from '@/components/QuestionsUI/StartLoading'

import start1 from '@/assets/images/start/start-1.png'
import start2 from '@/assets/images/start/start-2.png'
import start3 from '@/assets/images/start/start-3.png'
import start4 from '@/assets/images/start/start-4.png'
import start1Webp from '@/assets/images/start/start-1.webp'
import start2Webp from '@/assets/images/start/start-2.webp'
import start3Webp from '@/assets/images/start/start-3.webp'
import start4Webp from '@/assets/images/start/start-4.webp'
import startLow1 from '@/assets/images/start/start-1-low.jpg'
import startLow2 from '@/assets/images/start/start-2-low.jpg'
import startLow3 from '@/assets/images/start/start-3-low.jpg'
import startLow4 from '@/assets/images/start/start-4-low.jpg'
import startLow1Webp from '@/assets/images/start/start-1-low.webp'
import startLow2Webp from '@/assets/images/start/start-2-low.webp'
import startLow3Webp from '@/assets/images/start/start-3-low.webp'
import startLow4Webp from '@/assets/images/start/start-4-low.webp'
import arrow from '@/assets/images/base-arrow-circle.svg'

interface StartScreenProps {
  nextPath: string
}

export const StartScreen = (props: StartScreenProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  useAddUtmIfDev()

  const setToLocalStorageAgePlaceholder = (age: string) => {
    sessionStorage.setItem('placeholderAge', age)
  }

  const items = [
    {
      title: 'Age: 18-29',
      imgLow: startLow1,
      img: start1,
      imgWebpLow: startLow1Webp,
      imgWebp: start1Webp,
      placeholder: '20',
    },
    {
      title: 'Age: 30-39',
      imgLow: startLow2,
      imgWebpLow: startLow2Webp,
      img: start2,
      imgWebp: start2Webp,
      placeholder: '30',
    },
    {
      title: 'Age: 40-49',
      imgLow: startLow3,
      imgWebpLow: startLow3Webp,
      img: start3,
      imgWebp: start3Webp,
      placeholder: '40',
    },
    {
      title: 'Age: 50+',
      imgLow: startLow4,
      imgWebpLow: startLow4Webp,
      img: start4,
      imgWebp: start4Webp,
      placeholder: '50',
    },
  ]

  useEffect(() => {
    const startPath = ['/start', '/start/']
    let unmounted = false

    if (startPath.includes(location.pathname)) {
      const storedData = SessionStorageService.get('quizResult')
      const answers: Answers = storedData || false

      const paywallStatusString = sessionStorage.getItem('paywallStatus')
      if (!paywallStatusString || !answers) {
        trackEvent('start')

        const webVitalMetrics = [onFCP, onTTFB, onFID, onLCP]

        webVitalMetrics.forEach((metricFn) => {
          metricFn((data: Metric) => {
            if (unmounted) return

            const valueInSeconds = (data.value / 1000).toFixed(2)

            trackTimingComplete({
              type: data.name,
              value: parseFloat(valueInSeconds),
              quiz: QUIZ_NAME,
              version: QUIZ_VERSION,
            })
          })
        })
      } else {
        const newPaywallStatus = { currentStep: 0, showModal: false, wasShowing: true }
        newPaywallStatus.currentStep = 1
        newPaywallStatus.showModal = true
        newPaywallStatus.wasShowing = true
        sessionStorage.setItem('paywallStatus', JSON.stringify(newPaywallStatus))
        navigate('/paywall/', { replace: true })
      }
    }

    return () => {
      unmounted = true
    }
  }, [location])

  return (
    <>
      {/* <StartLoading /> */}

      <Layout>
        <div
          className={
            'opacity-100 visible p-5 absolute w-full h-full p-4 top-0 left-0 transition-all duration-300  flex flex-col overflow-y-auto overflow-x-hidden scrolling'
          }
        >
          <div className="absolute top-6 right-0 w-full move-burger">
            <Header
              title=""
              onBackClick={() => null}
              showBtn={false}
              isLastQuestion={false}
              isLastStep={false}
              hideHeader={false}
              hideBtnHeader={false}
              hideBackHeader={false}
            />
          </div>
          <h1 className="text-center font-semibold text-xl4 mx-auto mt-20 font-girloy">
            <span className="text-green">WALL PILATES</span> PLAN
          </h1>
          <div className="flex mt-2 justify-center flex-wrap gap-3 font-girloy text-[#1717178f] font-semibold">
            <p className="text-base">BASED ON YOUR AGE</p>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-7">
            {items.map((el) => (
              <div
                key={el.title}
                className="rounded-r12 cursor-pointer overflow-hidden col-span-1 bg-[#F6F7F9]"
                onClick={() => {
                  navigate(props.nextPath)
                  setToLocalStorageAgePlaceholder(el.placeholder)
                }}
              >
                <ProgressiveImage
                  className="max-w-full h-[140px] w-full object-cover mx-auto"
                  width="130"
                  height="160"
                  lowSrc={el.imgLow}
                  highSrc={el.img}
                  lowWebp={el.imgWebpLow}
                  highWebp={el.imgWebp}
                  alt="Image"
                />
                <span className="bg-green text-xl2 font-semibold font-girloy text-white w-full flex justify-between items-center  p-3">
                  {el.title}
                  <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                    <img className="w-2 ml-[2px]" width="5" height="12" src={arrow} alt="Image" />
                  </div>
                </span>
              </div>
            ))}
          </div>

          <div className="flex flex-col justify-end mt-6">
            <p className="text-xs2_1 text-center">
              By choosing your age and continuing you agree to our{' '}
              <Link className="transition-all hover:text-primary" to="/offer-policy">
                {t('Offer Policy')}
              </Link>{' '}
              |{' '}
              <Link className="transition-all hover:text-primary" to="/privacy-policy">
                {t('Privacy Policy')}
              </Link>
            </p>

            {COMPANY_NAME && COMPANY_ADDRESS && COMPANY_EMAIL && (
              <div className="grid grid-cols-1 mt-3">
                <p className="text-xs2 text-center">{COMPANY_NAME}</p>
                <p className="text-xs2 text-center">{COMPANY_ADDRESS}</p>
                <p className="text-xs2 text-center">{COMPANY_EMAIL}</p>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}
