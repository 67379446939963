import React from 'react'
import { Plan, PlanHubspot } from '@/types/types'
import { formattedPrice } from '@/helpers/formatPrice'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { Link } from 'react-router-dom'
import Image from '../Image/Image'
import { PRIMER_ID } from '@/constants/variables'

import arrow from '@/assets/images/arrow-right.svg'
import vector from '@/assets/images/vector.svg'
import vectorV2 from '@/assets/images/vectorV2.svg'
import vectorV2Active from '@/assets/images/vectorV2_active.svg'
import tick from '@/assets/scss/tick.svg'
import tickGreen from '@/assets/scss/tickGreen.svg'

interface PlansProps {
  descriptionTrial: string
  handleCheckout: () => void
  paymentMethod: string
  activePlan: number
  isDiscountPrice: boolean
  handlePlan: (index: number) => void
  plans: Plan[] | PlanHubspot[]
  version?: number
}

export function Plans(props: PlansProps) {
  const { t } = useTranslation()

  const handleCheckout = () => {
    sessionStorage.setItem('activePlan', JSON.stringify(props.plans[props.activePlan]))
    props.handleCheckout()
  }

  return (
    <div className="plans">
      {props.plans.map((el, index) => {
        const price = props.isDiscountPrice ? el.discountedPrice45 : el.discountedPrice30
        const pricePerDayDiscount = props.isDiscountPrice ? el.pricePerDay45 : el.pricePerDay30
        return props.version === 2 ? (
          <div className="relative mb-4">
            <div
              key={el.name}
              onClick={() => {
                props.handlePlan(index)
              }}
              className={`${index === props.activePlan ? 'active bg-white before:border-green2 after:opacity-100' : 'after:opacity-0'}  px-2.5 py-2.5 plan-item overflow-hidden mt-2  rounded-2xl  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-2xl before:transition-all  before:border-silverBorderV2 before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-2xl after:transition-all  after:border-green3 after:border-[3px] active:scale-95`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center pt-2 pl-1">
                  <div>
                    <span className="block font-bold text-xl12">
                      {el.name} {t('Plan')}
                    </span>
                    <span className="block text-xs4 flex">
                      <s className="text-gray9 decoration-plan">
                        {' '}
                        ${formattedPrice((PRIMER_ID ? el.primerPrice : el.originalPrice) ?? 0)}
                      </s>
                      <Image className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                      <span className="font-semibold">${formattedPrice(price)}</span>
                    </span>
                  </div>
                </div>
                <div className="text-price flex items-center justify-end text-center relative min-w-price p-1.5">
                  <Image
                    className="absolute top-1/2 right-0 -translate-y-1/2 w-[171px] h-[63px]"
                    src={index === props.activePlan ? vectorV2Active : vectorV2}
                    alt="vector"
                  />
                  <div
                    className={
                      'text-price flex items-normal justify-end text-center relative min-w-price gap-2.5'
                    }
                  >
                    <span className="block relative  ml-3">
                      <s
                        className={`block text-gray9 text-xs4 font-regular leading-none ${index === props.activePlan ? 'text-white opacity-70' : ''}`}
                      >
                        {' '}
                        ${formattedPrice(el.pricePerDay)}
                      </s>
                      <span
                        className={`text-xs4 text-black leading-none block font-medium ${index === props.activePlan ? 'text-white' : ''}`}
                      >
                        {t('per day')}
                      </span>
                    </span>
                    <span
                      className={`text-xl font-bold block font-girloy ${index === props.activePlan ? 'text-white' : ''}`}
                    >
                      ${pricePerDayDiscount.toFixed(2)}
                    </span>
                    <div
                      className={`${index === props.activePlan ? 'bg-lightGray border-green2 [&>img]:opacity-100' : 'bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder transition-all`}
                    >
                      <Image className="w-3" src={tickGreen} alt="tick" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {el.mostPopular && (
              <div
                className={
                  'bg-[#6DE187] text-center px-2 py-0.5 text-white font-bold text-xs2 transition-all absolute left-4 -top-1.5 rounded-[24px] bg-[linear-gradient(180deg,_#6DE187_0%,_#61CE75_100%)]'
                }
              >
                {t('MOST POPULAR')}
              </div>
            )}
          </div>
        ) : (
          <div
            key={el.name}
            onClick={() => {
              props.handlePlan(index)
            }}
            className={`${!el.mostPopular && index === props.activePlan ? 'bg-white' : 'bg-lightGray'} ${index === props.activePlan ? 'active bg-white before:border-green2 after:opacity-100' : 'after:opacity-0'}  p-1 plan-item overflow-hidden mt-2  rounded-lg  relative cursor-pointer transition-all before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full before:rounded-lg before:transition-all  before:border-checkBorder before:border after:absolute after:content-[''] after:top-0 after:left-0 after:w-full after:h-full after:rounded-lg after:transition-all  after:border-green2 after:border-2 active:scale-95`}
          >
            {el.mostPopular && (
              <div
                className={`${index === props.activePlan ? 'bg-green2' : 'bg-grayE'} text-center mb-1 -mt-1 -mx-6 px-4  text-white font-bold text-md transition-all`}
              >
                {t('MOST POPULAR')}
              </div>
            )}
            <div className="flex items-center justify-between">
              <div className="flex items-center pl-2">
                <div
                  className={`${index === props.activePlan ? 'bg-green2 border-green2 [&>img]:opacity-100' : 'bg-lightGray border-checkBorder [&>img]:opacity-0'} min-w-5 flex items-center justify-center max-w-5 h-5 rounded-full border border-checkBorder mr-4 transition-all`}
                >
                  <Image className="w-3" src={tick} alt="tick" />
                </div>
                <div>
                  <span className="block font-bold text-xl12">
                    {el.name} {t('Plan')}
                  </span>
                  <span className="block text-xs4 flex">
                    <s className="text-gray9 decoration-plan">
                      {' '}
                      ${formattedPrice((PRIMER_ID ? el.primerPrice : el.originalPrice) ?? 0)}
                    </s>
                    <Image className="w-4 h-auto mb-px" src={arrow} alt="arrow-right" />
                    <span className="font-semibold">${formattedPrice(price)}</span>
                  </span>
                </div>
              </div>
              <div className="text-price pl-4 flex items-center justify-end text-center relative min-w-price ">
                <Image
                  className="absolute top-1/2 right-0 -translate-y-1/2 h-full"
                  src={vector}
                  alt="vector"
                />
                <span className="block py-2 px-3 relative w-full">
                  <s className=" block text-gray9 text-xs4 font-regular leading-none">
                    {' '}
                    ${formattedPrice(el.pricePerDay)}
                  </s>
                  <span className="text-xl font-bold block">${pricePerDayDiscount.toFixed(2)}</span>
                  <span className="text-xs4 text-black leading-none block font-medium">
                    {t('per day')}
                  </span>
                </span>
              </div>
            </div>
          </div>
        )
      })}
      <p className="text-xs leading-4 mt-3 text-center text-[#5F626F] font-girloy">
        30 day{' '}
        <Link
          className={`${props.version === 2 ? 'text-[#17181D]' : 'text-green2'} underline`}
          to={'/money-back'}
        >
          money-back guarantee
        </Link>
      </p>
      <Button
        variant="plan"
        onClick={handleCheckout}
        className="mt-5  !p-4.5 cursor-pointer relative button-plan-animation"
      >
        {t('Get my plan')}
      </Button>
      <p className="mt-0 text-xs2 text-[#5F626F] font-normal font-girloy text-center mt-6">
        {/* eslint-disable-next-line react/jsx-no-comment-textnodes,@typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {props.plans[props.activePlan].instruction}
      </p>
    </div>
  )
}
