import React from 'react'
import { Answers } from '@/types/types'
import { useTranslation } from 'react-i18next'

interface BMIProps {
  active: boolean
  answers: Answers
  version?: number
  bmi: number
  percent: string
  color: number
  colors: any
}
export function BMI(props: BMIProps) {
  const { t } = useTranslation()

  return props.version === 2 ? (
    <div className={'p-4 mt-8 rounded-2xl border border-[#d7e5dacc] bg-white]'}>
      <div className="relative">
        <div className="absolute w-full top-[-28px] text-center">
          <span className="bg-white font-girloy text-base text-[#17181D] font-bold px-2">
            {t('Your Body Mass Index (BMI)')}:
          </span>
        </div>
        <h6 className="font-extrabold font-girloy text-[#17181D] text-xl3 text-center">
          <strong className={`font-extrabold text-[${props.colors[props.color].color}]`}>
            {props.colors[props.color].title}
          </strong>
        </h6>
        <div>
          <div className="relative pt-3">
            <div
              style={{ left: props.percent }}
              className={`${props.active ? 'scale-100 bmi-transition' : 'scale-50'} mb-5 absolute -bottom-1.5 -translate-x-1/2`}
            >
              <span
                className={`block relative py-[6px] px-[14px] text-white rounded-[54px] border-[3px] border-white bg-[${props.colors[props.color].color}] font-girloy text-xl font-bold whitespace-nowrap`}
              >
                {props.bmi.toFixed(0)}
              </span>
            </div>
            <div className="rounded-r2 w-full bg-progressLinearV2 h-[45px] bg-no-repeat bg-center bg-[100%_auto]"></div>
          </div>
          <div className="flex items-center justify-between px-2">
            <span className="block text-xs font-bold text-[#17181D]">{t('Underweight')}</span>
            <span className="block text-xs font-bold text-[#17181D]">{t('Normal')}</span>
            <span className="block text-xs font-bold text-[#17181D]">{t('Overweight')}</span>
            <span className="block text-xs font-bold text-[#17181D]">{t('Obese')}</span>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="p-4 rounded-r12 border border-borderInput bg-lightGray">
      {props.active}
      <div className="flex items-center justify-between">
        <h6 className="text-base font-extrabold text-dark">{t('Body Mass Index (BMI)')}</h6>
        <div className="bg-olive text-white font-extrabold text-xs2 py-1 px-2 rounded-md scale-60">
          {t('Normal')} - 21.5
        </div>
      </div>
      <div className="">
        <div className="relative pt-14">
          <div
            style={{ left: props.percent }}
            className={`${props.active ? 'scale-100 bmi-transition' : 'scale-50'} mb-6 absolute -bottom-1.5 -translate-x-1/2  after:-bottom-6 after:absolute after:content-['']  after:left-1/2 after:-translate-x-1/2 after:w-4 after:h-4 after:rounded-full  after:bg-olive after:border-2 after:border-white text-center`}
          >
            <span className="block relative -left-px py-1 px-1.5 rounded-lg bg-black text-white font-extrabold text-xs2 before:absolute before:content-['']  before:left-1/2 before:-translate-x-1/2 before:-bottom-0.5 before:w-2 before:h-2 before:bg-black before:rotate-45 whitespace-nowrap">
              {t('You')} — {props.bmi.toFixed(1)}
            </span>
          </div>
          <div className="rounded-r2 h-1 w-full bg-progressLinear"></div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <span className="block text-xs2 font-semibold text-dark">{t('Underweight')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Normal')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Overweight')}</span>
          <span className="block text-xs2 font-semibold text-dark">{t('Obese')}</span>
        </div>
      </div>
    </div>
  )
}
