import React, { useEffect, useMemo, useState } from 'react'
import { BMI } from './BMI'
import { Answer, Answers } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import { useTranslation } from 'react-i18next'
import Image from '@/components/Image/Image'

import weight from '@/assets/images/profile/weight.svg'
import goal from '@/assets/images/profile/goal.svg'
import level from '@/assets/images/profile/level.svg'
import diet from '@/assets/images/profile/tempo.svg'
import restriction from '@/assets/images/profile/restrictions.svg'

interface ProfileProps {
  active: boolean
  answers: Answers
  onAnswer: (answer: Answer, step?: number) => void
  version?: number
}
export function Profile(props: ProfileProps) {
  const { t } = useTranslation()
  const currentWeight = getValue('current-weight', props.answers)
  const goalWeight = getValue('goal-weight', props.answers)
  const rangeCurrentBody = getValue('range-current-body', props.answers)?.[0] ?? 0
  const dietVal = getValue('type-of-diet', props.answers)
  const fitnessLevel = getValue('fitness-level', props.answers)
  const arrayRestrictions = getValue('array-restrictions', props.answers)
  const [bmi, setBMI] = useState(15)
  const [percent, setPercent] = useState('7%')
  const [color, setColor] = useState(0)

  const currentBody = useMemo(() => {
    const current = Number(rangeCurrentBody)
    if (Number(rangeCurrentBody) <= 5) return current
    return 5
  }, [rangeCurrentBody])

  const restrictions = () => {
    if (typeof arrayRestrictions === 'string' && arrayRestrictions === 'None of the above') {
      return <p className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">No</p>
    }

    if (typeof arrayRestrictions === 'string') {
      return (
        <p className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">
          {arrayRestrictions}
        </p>
      )
    }

    if (typeof arrayRestrictions === 'object') {
      return arrayRestrictions.map((item, i) => (
        <p key={i} className="inline  text-dark text-xs2 font-semibold opacity-65 pr-1">
          {item}
          {i + 1 !== arrayRestrictions.length ? ',' : ' '}
        </p>
      ))
    }
  }

  const colors = [
    { bmi: 0, color: '#51ACC9', title: 'Underweight' },
    { bmi: 18.5, color: '#61CE75', title: 'Normal' },
    { bmi: 25, color: '#FFAE35', title: 'Overweight' },
    { bmi: 30, color: '#E15234', title: 'Obese' },
  ]

  useEffect(() => {
    const mathColor = () => {
      colors.forEach((option: any, key: number) => {
        if (Number(bmi) >= option.bmi) {
          setColor(key)
        }
      })
    }
    mathColor()
  }, [percent])

  useEffect(() => {
    if (props.active) {
      const heightProps = getValue('human-height', props.answers)
      const weightProps = getValue('current-weight', props.answers)
      if (heightProps && weightProps) {
        const tall =
          heightProps && heightProps[1] === 'FT' ? +heightProps[0] * 30.48 : +heightProps[0]
        const weight =
          weightProps && weightProps[1] === 'lbs' ? +weightProps[0] * 0.4536 : +weightProps[0]
        const bmi = weight / ((tall / 100) * (tall / 100))
        setBMI(bmi)
        if (bmi > 30) {
          setPercent('93%')
        } else if (bmi < 15) {
          setPercent('7%')
        } else {
          setPercent((bmi - 16) * 5.4 + 14 + '%')
        }
      }
    }
  }, [props.active])

  return (
    <div>
      <BMI
        answers={props.answers}
        active={props.active}
        version={props.version}
        bmi={bmi}
        percent={percent}
        color={color}
        colors={colors}
      />

      {props.version === 2 ? (
        <div
          className={`bg-profile_V2_${color} bg-cover w-full h-[260px] bg-no-repeat bg-center bg-[100%_auto] mt-6 flex flex-row justify-between overflow-hidden rounded-[16px] shadow-[0px_4px_12px_0px_rgba(0,0,0,0.05)_inset]`}
        >
          <div className="flex flex-col gap-2.5 justify-center pl-5">
            <div>
              <label className="text-[#5F626F] text-xs font-girloy font-normal m-0 w-[100px]">
                {t('Current weight')}
              </label>
              <span className="text-[#17181D] text-xl font-girloy font-bold">
                {currentWeight?.[0]} {currentWeight?.[1]}
              </span>
            </div>
            <div>
              <label className="text-[#5F626F] text-xs font-girloy font-normal m-0">
                {t('Diet')}
              </label>
              <span className="text-[#17181D] text-xl font-girloy font-bold">{dietVal}</span>
            </div>
            <div>
              <label className="text-[#5F626F] text-xs font-girloy font-normal m-0">
                {t('Goal')}
              </label>
              <span className="text-[#17181D] text-xl font-girloy font-bold">
                {goalWeight?.[0]} {goalWeight?.[1]}
              </span>
            </div>
            <div>
              <label className="text-[#5F626F] text-xs font-girloy font-normal m-0">
                {t('Fitness Level')}
              </label>
              <span className="text-[#17181D] text-xl font-girloy font-bold">{fitnessLevel}</span>
            </div>
          </div>
          <Image
            className="mx-auto transition-all duration-300 object-fill object-left "
            src={`/img/quiz-wallpilates-v6/body-fit-${currentBody + 1}.png`}
            webp={`/img/quiz-wallpilates-v6/body-fit-${currentBody + 1}.webp`}
            alt="body image"
          />
        </div>
      ) : (
        <div className="flex items-center">
          <div>
            <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
              <img className="min-w-6 mr-3" src={weight} width="24" height="24" alt="icon" />
              <div>
                <h6 className="font-extrabold">{t('Current weight')}</h6>
                <p className="block  text-dark text-xs2 font-semibold opacity-65">
                  {currentWeight?.[0]} {currentWeight?.[1]}
                </p>
              </div>
            </div>
            <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
              <img className="min-w-6 mr-3" src={goal} width="24" height="24" alt="icon" />
              <div>
                <h6 className="font-extrabold"> {t('Goal')} </h6>
                <p className="block  text-dark text-xs2 font-semibold opacity-65">
                  {goalWeight?.[0]} {goalWeight?.[1]}
                </p>
              </div>
            </div>
            <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
              <img className="min-w-6 mr-3" src={level} width="24" height="24" alt="icon" />
              <div>
                <h6 className="font-extrabold">{t('Fitness Level')}</h6>
                <p className="block  text-dark text-xs2 font-semibold opacity-65">{fitnessLevel}</p>
              </div>
            </div>
            <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
              <img className="min-w-6 mr-3" src={diet} width="24" height="24" alt="icon" />
              <div>
                <h6 className="font-extrabold">{t('Diet')}</h6>
                <p className="block  text-dark text-xs2 font-semibold opacity-65">{dietVal}</p>
              </div>
            </div>
            <div className="py-2 px-4 rounded-r12 bg-checked border border-green mt-2 flex items-center">
              <img className="min-w-6 mr-3" src={restriction} width="24" height="24" alt="icon" />
              <div>
                <h6 className="font-extrabold">{t('Restrictions')}</h6>
                <div className="leading-none">{restrictions()}</div>
              </div>
            </div>
          </div>
          <div className="-mr-5 relative min-w-body max-w-body">
            <Image
              className="max-w-48 w-full mx-auto transition-all duration-300 profile-image"
              src={`/img/range/range${currentBody}.jpg`}
              webp={`/img/range/range${currentBody}.webp`}
              alt="body image"
            />
          </div>
        </div>
      )}
    </div>
  )
}
