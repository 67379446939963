import React, { useEffect } from 'react'
import { Answer, Answers, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'

interface SelectButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  smallWidth?: boolean
}

export default function SelectCard(props: SelectButtonProps) {
  // const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = () => {
    props.onAnswer({
      [props.name]: [props.attributes.text],
    })
    props.triggerNextStep()
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      const inputs = document.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
  }, [])
  return (
    <div className="px-1" onClick={handleChange}>
      <div
        className="rounded-2xl"
        style={{
          boxShadow:
            'rgba(215, 229, 218, 0.8) 0px 4px 11px 0px, rgb(223 234 226 / 20%) -1px -4px 2px 4px inset',
        }}
      >
        <img
          className="w-auto max-w-full object-contain"
          width={40}
          height={40}
          alt="Radio img"
          src={props.attributes?.img}
        />
        <div className="flex justify-between items-center gap-2.5 py-3 px-4">
          <div className="text-[#17181d] font-bold">{props.attributes.text}</div>
          <div className="flex items-center rounded-full bg-[#24e57c] p-1 bg-gradient-to-b from-[#24E57C] to-[#13D16A]">
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="arrow-right">
                <path
                  id="Vector"
                  d="M3.75 9H14.25M14.25 9L9.75 13.5M14.25 9L9.75 4.5"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )
}
