import { PreCreateApi } from '@/app/api/user-api/pre-create-api'

interface PreCreateAnswer {
  id?: string
  error?: any
}

export const preCreateAccount = async (
  dataAnswer: object,
  isUpdate: boolean,
): Promise<{ body: PreCreateAnswer; isUpdate: boolean; status: number }> => {
  try {
    const { status, data } = isUpdate
      ? await PreCreateApi.preUpdate(dataAnswer)
      : await PreCreateApi.preCreate(dataAnswer)

    return {
      status,
      body: data,
      isUpdate,
    }
  } catch (e) {
    return { status: 412, body: { error: e }, isUpdate }
  }
}
