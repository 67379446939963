import React, { useEffect, useRef } from 'react'
import { Answer, Answers, RadioButtonType } from '@/types/types'
import { getValue } from '@/helpers/getObjectValue'
import Image from '@/components/Image/Image'

interface RadioButtonProps {
  attributes: RadioButtonType
  onAnswer: (answer: Answer) => void
  triggerNextStep: () => void
  name: string
  answers: Answers
  smallWidth?: boolean
}

export default function RadioButton(props: RadioButtonProps) {
  const checkbox = useRef<HTMLInputElement>(null)
  const handleChange = () => {
    props.onAnswer({
      [props.name]: [props.attributes.text],
    })
    props.triggerNextStep()
  }
  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue) {
      const inputs = document.querySelectorAll('input')
      inputs.forEach((input) => {
        if (input.value === choosedValue) {
          input.checked = true
        }
      })
    }
  }, [])
  return (
    <div>
      {!props.attributes?.listSelect ? (
        props.attributes?.withTick ? (
          <label
            className={`
            flex bg-white items-center checkbox font-semibold border relative border-border mb-2 mt-0 rounded-xl px-3 py-2 min-h-16 cursor-pointer  transition duration-300 active:scale-95 '
            ${props.attributes?.hideChecked ? '' : ' pr-14 after:absolute after:content=[""] after:top-1/2 after:w-5 after:h-5  after:border after:border-checkBorder after:bg-check after:right-5 after:rounded-md after:-translate-y-1/2 '}
            ${
              checkbox.current?.checked
                ? ' !bg-checked  border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            } 
            ${props.attributes.smallWidth ? ' w-2/3 ' : ''} 
            ${props.attributes.smallWidthRight ? ' w-1/2 mr-0 ml-auto' : ''} 
            ${props.attributes?.bigSize ? ' h-[82px] ' : ''}
            ${props.attributes?.bgImg ? ' pl-[90px] ' : ''}
          `}
          >
            {props.attributes.bgImg && (
              <Image
                className="h-full w-auto absolute left-0 top-0"
                alt="Body img"
                src={props.attributes?.bgImg?.src}
                webp={props.attributes?.bgImg?.srcWebp}
              />
            )}
            {props.attributes?.img && (
              <span className="flex items-center min-w-6 max-w-6 mr-4">
                <img
                  className="max-w-6 h-auto w-auto"
                  width={24}
                  height={24}
                  alt="Radio img"
                  src={props.attributes?.img}
                />
              </span>
            )}
            <span className={`${!props.attributes?.img ? 'pl-2' : ''} text-xl text-semibold`}>
              {props.attributes.text}
              {props.attributes?.small && (
                <small className="block  text-dark text-xs2 font-semibold opacity-65">
                  {props.attributes.small}
                </small>
              )}
            </span>
            <input
              type="radio"
              onClick={handleChange}
              name={props.name}
              value={props.attributes.text}
              className="hidden"
              ref={checkbox}
            />
          </label>
        ) : (
          <label
            className={`
            font-semibold text-xl text-dark border transition duration-300 border-radio  mb-2 mt-0  rounded-xl cursor-pointer flex items-center bg-transparent active:scale-95 relative overflow-hidden after:absolute after:content=[''] after:top-1/2 after:w-5 after:h-5  after:border after:border-checkBorder after:bg-check after:right-5 after:rounded-md after:-translate-y-1/2 active:scale-95
            ${checkbox.current?.checked ? ' !bg-checked !border-green ' : ''}
            ${props.smallWidth ? ' w-2/3' : ''}
            ${
              checkbox.current?.checked
                ? ' !bg-checked border-green after:bg-green after:border-green border-borderChecked after:bg-tick after:bg-center after:bg-60% after:bg-no-repeat '
                : ''
            }
            `}
          >
            {props.attributes?.img && !props.attributes.imgWebp && (
              <img
                className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
                width={358}
                height={96}
                alt="Body img"
                src={props.attributes?.img}
              />
            )}
            {props.attributes.imgWebp && (
              <Image
                className={(props.attributes?.img && 'w-full') + ' max-w-full  w-auto'}
                width={358}
                height={96}
                alt="Body img"
                src={props.attributes?.img}
                webp={props.attributes?.imgWebp}
              />
            )}
            <span className="absolute block top-1/2 -translate-y-1/2 left-28">
              {props.attributes.text}
            </span>
            <input
              type="radio"
              onClick={handleChange}
              name={props.name}
              value={props.attributes.text}
              className="hidden"
              ref={checkbox}
            />
          </label>
        )
      ) : (
        <div className="px-1 mb-2" onClick={handleChange}>
          <div
            className="rounded-2xl"
            style={{
              boxShadow:
                'rgba(215, 229, 218, 0.8) 0px 4px 11px 0px, rgb(223 234 226 / 20%) -1px -4px 2px 4px inset',
            }}
          >
            <div className="flex justify-between items-center gap-2.5 py-3 pl-3 pr-4">
              <div className="flex items-center gap-2.5">
                {props.attributes.bgImg && (
                  <Image
                    className="h-[64px] w-auto"
                    alt="Body img"
                    src={props.attributes?.bgImg?.src}
                    webp={props.attributes?.bgImg?.srcWebp}
                    height={40}
                  />
                )}
                <div>
                  <div className="text-[#17181d] text-xl font-bold font-girloy">
                    {props.attributes.text}
                  </div>
                  {props.attributes.description && (
                    <div className="text-[#5F626F] text-base font-normal font-girloy">
                      {props.attributes.description}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center rounded-full bg-[#24e57c] p-1 bg-gradient-to-b from-[#24E57C] to-[#13D16A]">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="arrow-right">
                    <path
                      id="Vector"
                      d="M3.75 9H14.25M14.25 9L9.75 13.5M14.25 9L9.75 4.5"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
