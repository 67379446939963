import React from 'react'
import { useTranslation } from 'react-i18next'
import Image from '@/components/Image/Image'
import { getValue } from '@/helpers/getObjectValue'
import { Answers } from '@/types/types'

const currentBodyFat: Record<string, string> = {
  '0': '10-14%',
  '1': '15-19%',
  '2': '20-24%',
  '3': '25-29%',
  '4': '30-34%',
  '5': '35-39%',
  '6': '40-44%',
  '7': '>45%',
}

interface HeroProps {
  bodyFatIndex: string
  answers: Answers
}

export default function Hero({ bodyFatIndex, answers }: HeroProps) {
  const { t } = useTranslation()
  const fitnessLevel = getValue('fitness-level', answers)
  const fitnessLevelPercent =
    fitnessLevel === 'Beginner' ? 27 : fitnessLevel === 'Intermediate' ? 59 : 72

  const getAverage = (range: string): number => {
    const [minStr, maxStr] = range.replace('%', '').split('-')
    const min = Number(minStr)
    const max = Number(maxStr)
    return (min + max) / 2
  }

  const ProgressLine = ({ title, percent, color = '', value }) => (
    <div className="">
      <div className="mb-4">
        <div className="flex items-center justify-between">
          <span className="font-normal font-girloy">{title}</span>
          <span className="font-extrabold">
            <span>{percent}</span>
          </span>
        </div>
        <div className="h-[12px] bg-[#15331b0d] w-full rounded-r8 mt-2">
          <span
            style={{ width: value + '%' }}
            className={`h-[12px] rounded-r8 block ${color || (value > 66 ? 'bg-[#61CE75]' : value > 33 ? 'bg-[#E8BE5F]' : 'bg-[#DE5769]')}`}
          ></span>
        </div>
      </div>
    </div>
  )

  return (
    <div className="pb-1.5 mt-1 px-4">
      <div className="flex flex-row gap-2 relative">
        <div className="w-full bg-[#FBFFFC] border border-[#E3F0E6] rounded-r15">
          <Image
            className="h-40 w-auto object-contain mx-auto"
            src={`/img/range/range-${bodyFatIndex}.png`}
            webp={`/img/range/range-${bodyFatIndex}.webp`}
            alt="body image"
          />
          <h2 className="text-center text-[#17181D] text-base font-bold font-girloy m-0 p-0 py-2.5">
            Now
          </h2>
          <div className="p-4 pb-2 border-t border-t-[#E3F0E6]">
            <ProgressLine
              title={t('Body fat')}
              percent={currentBodyFat[bodyFatIndex]}
              value={getAverage(currentBodyFat[bodyFatIndex])}
            />
            <ProgressLine
              title={t('Fitness Level')}
              percent={`${fitnessLevelPercent}%`}
              value={fitnessLevelPercent}
            />
          </div>
        </div>
        <div className="w-full bg-[#FBFFFC] border border-[#E3F0E6] rounded-r15">
          <Image
            className="h-40 w-auto object-contain mx-auto"
            src={'/img/range/range-0.png'}
            webp={'/img/range/range-0.webp'}
            alt="body image"
          />
          <h2 className="text-center text-[#61CE75] text-base font-bold font-girloy m-0 p-0 py-2.5 border-b border-b-[#E3F0E6]">
            Goal
          </h2>
          <div className="p-4 pb-2 border-t border-t-[#E3F0E6]">
            <ProgressLine
              title={t('Body fat')}
              percent={'10-14%'}
              value={14}
              color="bg-[#61CE75]"
            />
            <ProgressLine
              title={t('Fitness Level')}
              percent={'86%'}
              value={86}
              color="bg-[#61CE75]"
            />
          </div>
        </div>

        <div className="absolute w-full top-36">
          <Image
            className="w-16 mx-auto shadow-[0px_20px_50px_0px_rgba(122,236,110,0.26)]"
            src={'/img/quiz-wallpilates-v6/body-arrow-to-right.png'}
            webp={'/img/quiz-wallpilates-v6/body-arrow-to-right.webp'}
            alt="arrow body image"
          />
        </div>
      </div>
    </div>
  )
}
