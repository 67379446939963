export const getBucketValue = (key: any, salt: any) => {
  const str = key + salt
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash |= 0
  }

  return Math.abs(hash) % 101
}
