import React, { useEffect, useRef, useState } from 'react'
import Layout from '@/components/Layout'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { useQuery } from 'react-query'
import { Answers, Customer, PaywallContent, Plan, PlanHubspot } from '@/types/types'
import Timer from '@/components/PaywallUI/Timer'
import { Plans } from '@/components/PaywallUI/Plans'
import Review from '@/components/QuestionsUI/Review'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'
import Hero from '../PaywallUI/Hero'
import { getValue } from '@/helpers/getObjectValue'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@radix-ui/react-accordion'
import { trackFeatureAnalytic, trackLead } from '@/helpers/facebookPixelEvents'
import Percent45Overlay from '@/components/PaywallUI/Percent45Overlay'
import { PlansHeader } from '@/components/PaywallUI/PlansHeader'
import PlansTimer from '../PaywallUI/PlansTimer'
import { PaymentPlanApi } from '@/app/api/payment-plan-api/payment-plan-api'
import { CHECKOUT_URL, PRIMER_ID, QUIZ_NAME, QUIZ_VERSION } from '@/constants/variables'
import { SessionStorageService } from '@/services/session-storage-service'
import Image from '../Image/Image'
import { encryptField } from '@/helpers/encryptField'
import { PlansHubspot } from '@/components/PaywallUI/PlansHubspot'
import useFeatureFlag from '@/hooks/featureFlag/useFeatureFlag'
import PlansTimerTicketPink from '@/components/PaywallUI/PlansTimerTicketPink'

import arrow from '@/assets/images/accordion-arrow.svg'

const transition = '300ms all, 300ms all'
const transitionStylesModal = {
  entered: {
    top: '0px',
  },
  exited: {
    top: '100%',
  },
}
const defaultStyleModal = {
  transition,
  top: '100%',
}

interface PaywallProps {
  lang: string
  paywallFile: string
  paymentMethod: string
}

export interface PaywallStatus {
  currentStep: number
  showModal: boolean
  wasShowing?: boolean
}

export default function Paywall(props: PaywallProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { variant, loading: loadingFeature } = useFeatureFlag('paywall-testing', 'standart')

  const nodeRef = useRef(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const planElement = useRef<HTMLDivElement>(null)
  const topPage = useRef<HTMLDivElement>(null)

  const [activePlan, setActivePlan] = useState(1)
  const [paywallStatus, setPaywallStatus] = useState<PaywallStatus>(
    (SessionStorageService.get('paywallStatus') as PaywallStatus) || {
      currentStep: 0,
      showModal: false,
    },
  )

  const storedData = SessionStorageService.get('quizResult')
  const answers: Answers = storedData || false

  const userId = searchParams.get('_id')
  const [loadTimer, setLoadTimer] = useState(false)
  const [goal, setGoal] = useState(getValue('main-goal', answers))
  const [goalWeight, setGoalWeight] = useState(getValue('goal-weight', answers))
  const [bodyFatIndex, setBodyFatIndex] = useState(
    getValue('range-current-body', answers)?.[0] ?? '0',
  )

  const [customer, setCustomer] = useState<Customer>({
    city: SessionStorageService.get('city'),
    email: getValue('email', answers) as string,
    address: SessionStorageService.get('city'),
    paraml: encryptField((getValue('your-lastname', answers) as string) ?? ''), // 'John',
    paramf: encryptField((getValue('your-name', answers) as string) ?? ''), // 'John',
    postalCode: SessionStorageService.get('postalCode') || '90071',
    countryCode: SessionStorageService.get('countryCode') || 'US',
    customerId: SessionStorageService.get('customerId') || '',
  })

  const isDiscountPrice = paywallStatus.currentStep >= 1

  const { data: plans, isLoading: isLoadingPlans } = useQuery({
    queryKey: ['plans', isDiscountPrice],
    queryFn: () =>
      PRIMER_ID
        ? PaymentPlanApi.findPrimer(isDiscountPrice ? '2' : '1')
        : PaymentPlanApi.find('stripeV1'),
    select(data: any) {
      if (PRIMER_ID) {
        return data.items.filter(
          (plan: any) => plan.properties.producttype === 'plan',
        ) as PlanHubspot[]
      } else {
        const formatData = data.items.map((item: any) => ({
          name: item.name,
          ...item.plans.find((plan) => plan.type === 'stripeV1'),
        }))

        return formatData as Plan[]
      }
    },

    retry: 5,
    retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
  })

  const { data: paywallContent } = useQuery({
    queryKey: ['paywallContent'],
    enabled: !!props.lang && !!props.paywallFile,
    queryFn: async () => {
      const response: PaywallContent = await import(
        `../../locales/${props.lang}/${props.paywallFile}`
      )
      return response
    },
    onError(error) {
      console.error('Error fetching paywall data:', error)
    },
  })

  const loadUserData = async (userId: string): Promise<{ status: number; body: any }> => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_CREATE_ACCOUNT_ENDPOINT}/api/v1/account/quiz/data?_id=${userId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        },
      )

      const data = await response.json()
      return { status: response.status, body: data }
    } catch (e) {
      return { status: 412, body: { error: e } }
    }
  }

  const handleUserData = async (userId: string) => {
    try {
      const endTime = new Date()
      endTime.setTime(endTime.getTime() + 10 * 60 * 1000)
      sessionStorage.setItem('quizResult', JSON.stringify(answers))
      sessionStorage.setItem('endTimer', endTime.toISOString())
      setLoadTimer(true)

      const { body: data } = await loadUserData(userId)

      sessionStorage.setItem('customerId', data?._id as string)
      localStorage.setItem(
        'email',
        data?.preCreateEmails[data?.preCreateEmails?.length - 1] as string,
      )

      setCustomer({
        customerId: data?._id,
        email: data?.preCreateEmails[data?.preCreateEmails?.length - 1] as string,
        paramf: encryptField(data?.profile?.name as string),
        paraml: encryptField(''),
        postalCode: SessionStorageService.get('postalCode') || '90071',
        countryCode: SessionStorageService.get('countryCode') || 'US',
      })

      setGoal(data?.profile?.goals?.name || '')
      const isMetricUS = data?.metricType === 'US'
      const weightValue = parseInt(
        isMetricUS
          ? (data?.profile?.condition?.weight?.wanted?.pounds ?? '0')
          : (data?.profile?.condition?.weight?.wanted?.kilograms ?? '0'),
      ).toString()

      const weightUnit = isMetricUS ? 'lbs' : 'kg'

      setGoalWeight([weightValue, weightUnit])
      setBodyFatIndex(data?.profile?.condition?.weight?.index ?? '0')
      setPaywallStatus({
        currentStep: 1,
        showModal: false,
      })

      setLoadTimer(false)
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    trackLead()

    const paywallStatusString = sessionStorage.getItem('paywallStatus')
    if (!paywallStatusString) handleChangePaywallStatus(false, 0)

    if (userId) {
      handleUserData(userId)
    }
  }, [])

  useEffect(() => {
    if (!answers && !userId) {
      navigate('/')
    }
  }, [answers])

  useEffect(() => {
    if (!loadingFeature) {
      const tracked = sessionStorage.getItem('tracked-paywall-testing')
      if (!tracked) {
        trackFeatureAnalytic({
          variant,
          type: 'paywall-testing',
          page: 'paywall',
          quiz: { v: QUIZ_VERSION, name: QUIZ_NAME },
        })
        sessionStorage.setItem('tracked-paywall-testing', 'true')
      }
    }
  }, [loadingFeature, variant])

  const handleCheckout = () => {
    const params = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      planId: plans?.[activePlan]?._id ?? plans?.[activePlan]?.id ?? '',
      ...customer,
    }

    const checkoutUrl = new URL(CHECKOUT_URL)

    for (const key in params) {
      checkoutUrl.searchParams.append(key, params[key])
    }

    const dietVegan = getValue('type-of-diet', answers) !== 'Traditional'

    if (isDiscountPrice) checkoutUrl.searchParams.append('isDiscountPrice', 'true')
    if (dietVegan) checkoutUrl.searchParams.append('vegan', 'true')
    if (paywallStatus.currentStep === 0) handleChangePaywallStatus(true, 1)

    window.location.href = checkoutUrl.href
  }

  const handleSavePaywallStatus = (modal: boolean, step?: number) => {
    SessionStorageService.set('paywallStatus', {
      currentStep: step ?? paywallStatus.currentStep,
      showModal: modal,
    })
  }

  const handleChangePaywallStatus = (modal: boolean, step?: number) => {
    setPaywallStatus({ currentStep: step ?? paywallStatus.currentStep, showModal: modal })
    handleSavePaywallStatus(modal, step)
  }

  const handleScrollToPlan = () => {
    const planOffsetTop = planElement.current?.offsetTop || 0
    containerRef.current?.scroll(0, planOffsetTop - 70)
  }

  const handlePlan = (index: number) => {
    setActivePlan(index)
  }

  if (isLoadingPlans)
    return (
      <div className="fixed top-0 z-30 left-0 bg-white w-full h-[calc(100%-60px)]">
        <div className="spinner z-30"></div>
      </div>
    )

  return (
    (answers || userId) && (
      <Layout>
        <div ref={containerRef} className="overflow-y-auto min-h-full p-4 pt-0 overflow-x-hidden">
          <div ref={topPage}></div>
          <div className="pb-2 pt-2 sticky top-0 bg-white z-50  flex items-center justify-between">
            <div>
              {paywallStatus.currentStep === 0 && (
                <p className="text-xs2 text-dark leading-5">
                  <b>61%</b> discount reserved for
                </p>
              )}
              {isDiscountPrice && (
                <p className="text-xs2 text-dark leading-5">
                  <b>71%</b> discount reserved for
                </p>
              )}

              <Timer loadTimer={loadTimer} className={'text-xl3 text-timer font-extrabold mr-6'} />
            </div>
            <Button onClick={handleScrollToPlan} variant="plan" className="w-[55%]">
              {t('get my plan')}
            </Button>
          </div>
          {isDiscountPrice && (
            <>
              <p className="text-center line-through">
                Previous discount: <span className="text-green2">61%</span>
              </p>
              <div className="bg-[#8DECB4] flex items-center mt-3 mb-6 py-3 px-4 rounded-2xl">
                <span className="text-[50px] mr-2">🎁</span>
                <p className="text-center font-bold">
                  Get your Personal Wall Pilates Plan with up to{' '}
                  <span className="text-plan">71%</span> discount
                </p>
              </div>
            </>
          )}
          <Hero bodyFatIndex={bodyFatIndex} />
          <p className="text-xs2 leading-6 text-[#B3C1CD] mb-7">
            This is not a guarantee or promise of results
          </p>
          <div
            id="plan"
            ref={planElement}
            className="pt-4.5 pb-8 px-6 rounded-r15 border border-borderInput"
          >
            <PlansHeader paywallContent={paywallContent} goal={goal} goalWeight={goalWeight} />

            {loadingFeature ? null : variant.key === 'feature' ? (
              <PlansTimerTicketPink />
            ) : (
              <PlansTimer />
            )}

            {plans &&
              paywallContent &&
              (PRIMER_ID ? (
                <PlansHubspot
                  activePlan={activePlan}
                  isDiscountPrice={isDiscountPrice}
                  handleCheckout={handleCheckout}
                  handlePlan={handlePlan}
                  paymentMethod={props.paymentMethod}
                  descriptionTrial={paywallContent.plans?.descriptionTrial}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  plans={plans}
                />
              ) : (
                <Plans
                  activePlan={activePlan}
                  isDiscountPrice={isDiscountPrice}
                  handleCheckout={handleCheckout}
                  handlePlan={handlePlan}
                  paymentMethod={props.paymentMethod}
                  descriptionTrial={paywallContent.plans?.descriptionTrial}
                  plans={plans}
                />
              ))}
          </div>
          {paywallContent?.checkoutBlock && (
            <div className="pt-5 pb-5 px-2 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-4 text-center mb-4"
                dangerouslySetInnerHTML={{ __html: paywallContent.checkoutBlock.title }}
              ></h3>
              <div className="flex items-center justify-between flex-wrap px-7">
                {paywallContent?.checkoutBlock.items?.map((el) => (
                  <Image className="max-h-9 my-3 mx-2.5" key={el} src={el} alt="Image" />
                ))}
                <span className="w-8"></span>
              </div>
            </div>
          )}

          {paywallContent?.benefitsBlock && (
            <div className="pt-5 pb-8 px-3 pr-8 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-3 -mr-8 text-center mb-7"
                dangerouslySetInnerHTML={{ __html: paywallContent.benefitsBlock.title }}
              ></h3>
              {paywallContent?.benefitsBlock?.items && (
                <ul className="list-none">
                  {paywallContent?.benefitsBlock?.items?.map((el, index) => (
                    <li className="flex items-center mt-6" key={index}>
                      <div className="max-w-11 min-w-11 h-11 rounded-full bg-checked flex items-center justify-center mr-2.5">
                        <Image className="w-6" src={el.src} alt="Icon" />
                      </div>
                      <p className="font-semibold leading-5">{el.text}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}

          {paywallContent?.featuredBlock && (
            <div className="pt-5 pb-5 px-2 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12  font-extrabold -mx-4 text-center mb-6"
                dangerouslySetInnerHTML={{ __html: paywallContent.featuredBlock.title }}
              ></h3>
              <div className="flex items-center justify-center flex-wrap">
                {paywallContent?.featuredBlock.items?.map((el) => (
                  <Image className="max-h-6 m-3 mx-2" key={el} src={el} alt="Image" />
                ))}
              </div>
            </div>
          )}

          {paywallContent?.testimonialsBlock && (
            <div className="mt-7 ">
              <h3 className="px-12  min-[364px]:px-14 min-[394px]:px-16 min-[412px]:px-20 text-center text-xl font-extrabold bg-gradient-to-r from-lightBlue to-border bg-clip-text text-transparent mb-2">
                {paywallContent?.testimonialsBlock.title}
              </h3>
              {paywallContent?.testimonialsBlock?.reviews?.map((el) => (
                <Review
                  key={el.title + el.name}
                  title={el.title}
                  quote={el.quote}
                  stars={el.stars}
                  src={el.src}
                  srcWebp={el.srcWebp}
                  name={el.name}
                  class={el.class}
                  trustpilot={el.trustpilot}
                  mainTitle={el.mainTitle}
                />
              ))}
            </div>
          )}
          {paywallContent?.faqBlock && (
            <div className="pt-4 pb-8 px-5 rounded-r15 border border-borderInput mt-7">
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12 max-w-64 mx-auto  font-extrabold text-center mt-4 mb-7"
                dangerouslySetInnerHTML={{ __html: paywallContent.faqBlock.title }}
              ></h3>
              <Accordion type="single" collapsible>
                {paywallContent?.faqBlock.items?.map((item, index) => (
                  <AccordionItem
                    key={index}
                    className="border-b border-border"
                    value={`item-${index}`}
                  >
                    <AccordionTrigger className="flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all  [&[data-state=open]>img]:rotate-180 text-base font-semibold w-full flex items-center justify-between text-left">
                      {item.title}
                      <Image
                        className="h-4 w-4 shrink-0 text-muted-foreground transition-transform duration-200 ml-4"
                        src={arrow}
                        alt="arrow"
                      />
                    </AccordionTrigger>
                    <AccordionContent className="overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
                      <p className="pb-7 text-left text-light text-md">{item.text}</p>
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </div>
          )}

          <div className="pt-4.5 pb-8 px-6 rounded-r15 border border-borderInput mt-7">
            <PlansHeader paywallContent={paywallContent} goal={goal} goalWeight={goalWeight} />

            {loadingFeature ? null : variant.key === 'feature' ? (
              <PlansTimerTicketPink />
            ) : (
              <PlansTimer />
            )}

            {plans &&
              paywallContent &&
              (PRIMER_ID ? (
                <PlansHubspot
                  activePlan={activePlan}
                  isDiscountPrice={isDiscountPrice}
                  handleCheckout={handleCheckout}
                  handlePlan={handlePlan}
                  paymentMethod={props.paymentMethod}
                  descriptionTrial={paywallContent.plans?.descriptionTrial}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  plans={plans}
                />
              ) : (
                <Plans
                  activePlan={activePlan}
                  isDiscountPrice={isDiscountPrice}
                  handleCheckout={handleCheckout}
                  handlePlan={handlePlan}
                  paymentMethod={props.paymentMethod}
                  descriptionTrial={paywallContent.plans?.descriptionTrial}
                  plans={plans}
                />
              ))}
          </div>
          {paywallContent?.moneyBackBlock && (
            <div className="pt-7 pb-8 px-5 rounded-r15 border border-borderInput mt-7">
              <Image
                className="w-13 mx-auto"
                src={paywallContent?.moneyBackBlock.img}
                alt="100% money-back guarantee"
              />
              <h3
                className="[&>mark]:bg-transparent [&>mark]:text-green2 text-xl12 max-w-64 mx-auto  font-extrabold text-center mt-4 mb-5"
                dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.title }}
              ></h3>
              <p className="w-full text-md text-center leading-5">
                {paywallContent?.moneyBackBlock.content}{' '}
                <Link
                  className="transition-all hover:text-primary text-blue underline"
                  to="/money-back"
                  dangerouslySetInnerHTML={{ __html: paywallContent.moneyBackBlock.link }}
                ></Link>
              </p>
            </div>
          )}
          {paywallContent?.copyright && (
            <p
              className="px-4 py-7 pb-3 text-center text-xs2 font-medium text-grayB [&>span]:px-px"
              dangerouslySetInnerHTML={{ __html: paywallContent.copyright }}
            ></p>
          )}

          <Transition
            nodeRef={nodeRef}
            timeout={100}
            onExited={() => topPage.current?.scrollIntoView()}
            in={isDiscountPrice && paywallStatus.showModal}
          >
            {(state: string) => {
              return (
                <div
                  ref={nodeRef}
                  style={{
                    ...defaultStyleModal,
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ...transitionStylesModal[state],
                  }}
                  className="absolute left-1/2 z-[100] -translate-x-1/2 h-full max-w-content w-full flex items-end backdrop-blur-md top-0"
                >
                  <Percent45Overlay
                    isShow={paywallStatus.showModal && isDiscountPrice}
                    onCloseOverlay={() => {
                      handleChangePaywallStatus(false)
                    }}
                  />
                </div>
              )
            }}
          </Transition>
        </div>
      </Layout>
    )
  )
}
