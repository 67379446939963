import { returnDataObject } from '@/helpers/returnDataObject'

const preCreateBuildData = (
  customerId: string | null,
  email: string,
  answers: any,
  preConfig: any,
) => {
  if (customerId) {
    return {
      data: { email, _id: customerId },
      isUpdate: true,
    }
  }

  const data = returnDataObject(answers, preConfig)
  if (!data.profile.dailyActivity) {
    data.profile.dailyActivity = 'low'
  }
  return {
    data,
    isUpdate: false,
  }
}

export default preCreateBuildData
