import React from 'react'
import { Answers } from '@/types/types'
import { generatePromoCode } from '@/helpers/generatePromoCode'
import useCountdownTimer from '@/hooks/useCountdownTimer'

const PlansTimerTicketPink: React.FC = () => {
  const timeDifference = useCountdownTimer()

  const storedAnswers = sessionStorage.getItem('answers')
  const answers: Answers = storedAnswers ? JSON.parse(storedAnswers) : { 0: [] }

  const code: string = generatePromoCode(answers)

  const minutes: number = Math.floor((timeDifference ?? 0) / (1000 * 60))
  const seconds: number = Math.floor(((timeDifference ?? 0) % (1000 * 60)) / 1000)

  return (
    <div className="flex justify-center items-center">
      <div
        className="relative rounded-xl shadow-xl overflow-hidden"
        style={{
          background: 'linear-gradient(120deg, #FEC118 0%, #EF49B8 100%)',
          width: '100%',
        }}
      >
        <div
          className="absolute left-0 transform -translate-y-1/2 -translate-x-1/2 bg-white"
          style={{ width: '20px', height: '20px', borderRadius: '9999px', top: '56px' }}
        />
        <div
          className="absolute right-0 transform -translate-y-1/2 translate-x-1/2 bg-white"
          style={{ width: '20px', height: '20px', borderRadius: '9999px', top: '56px' }}
        />

        <div
          className="px-4 py-4 flex items-center justify-center text-center"
          style={{
            background: 'linear-gradient(120deg, #FFB428 0%, #EF49B8 100%)',
          }}
        >
          <div className="relative flex-shrink-0">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_415_938)">
                <path
                  d="M2.5 6.33326L9.5375 1.64159C9.67444 1.55022 9.83538 1.50146 10 1.50146C10.1646 1.50146 10.3256 1.55022 10.4625 1.64159L17.5 6.33326V17.9999C17.5 18.2209 17.4122 18.4329 17.2559 18.5892C17.0996 18.7455 16.8877 18.8333 16.6667 18.8333H3.33333C3.11232 18.8333 2.90036 18.7455 2.74408 18.5892C2.5878 18.4329 2.5 18.2209 2.5 17.9999V6.33326ZM10 9.66659C10.442 9.66659 10.866 9.49099 11.1785 9.17843C11.4911 8.86587 11.6667 8.44195 11.6667 7.99992C11.6667 7.5579 11.4911 7.13397 11.1785 6.82141C10.866 6.50885 10.442 6.33326 10 6.33326C9.55797 6.33326 9.13405 6.50885 8.82149 6.82141C8.50893 7.13397 8.33333 7.5579 8.33333 7.99992C8.33333 8.44195 8.50893 8.86587 8.82149 9.17843C9.13405 9.49099 9.55797 9.66659 10 9.66659Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_415_938">
                  <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <span className="ml-2 text-white text-xl font-semibold">Your promo code applied!</span>
        </div>

        <div className="flex items-center space-x-2 p-3">
          <div className="flex justify-start items-center flex-1 bg-white rounded-xl shadow-inner h-[54px] overflow-hidden px-2">
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-[40px]"
            >
              <path
                d="M3 8L6 11L12 5"
                stroke="#61CE75"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="font-bold text-xl ml-1 truncate">{code}</span>
          </div>

          <div className="flex space-x-1 items-center">
            <div className="bg-[#ffffff1c] rounded-xl px-2 pt-2 pb-1 flex flex-col items-center justify-end w-[60px] h-[54px]">
              <span className="text-white font-bold text-xl3 leading-[25px]">
                {String(minutes).padStart(2, '0')}
              </span>
              <span className="text-[#F5F5F5] opacity-60 text-xs2">min</span>
            </div>
            <div className="text-white font-bold text-xl3">:</div>
            <div className="bg-[#ffffff1c] rounded-xl px-2 pt-2 pb-1 flex flex-col items-center justify-end w-[60px] h-[54px]">
              <span className="text-white font-bold text-xl3 leading-[25px]">
                {String(seconds).padStart(2, '0')}
              </span>
              <span className="text-[#F5F5F5] opacity-60 text-xs2">sec</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansTimerTicketPink
