import { useEffect } from 'react'

const useAddUtmIfDev = (): void => {
  useEffect(() => {
    if (process.env.REACT_APP_MODE === 'dev') {
      const currentUrl = new URL(window.location.href)
      if (!currentUrl.searchParams.has('utm_source')) {
        currentUrl.searchParams.set('utm_source', 'test')
        currentUrl.searchParams.set('utm_medium', 'test')
        currentUrl.searchParams.set('utm_campaign', 'test')
        currentUrl.searchParams.set('utm_content', 'test')
        currentUrl.searchParams.set('utm_term', 'test')
        window.history.replaceState(null, '', currentUrl.toString())
      }
    }
  }, [])
}

export default useAddUtmIfDev
