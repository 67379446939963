import React, { useEffect, useState } from 'react'

interface TimerProps {
  loadTimer: boolean
  className?: string
}

export default function Timer({ loadTimer, className }: TimerProps) {
  const [endTime, setEndTime] = useState<Date | null>(null)
  const [timeDifference, setTimeDifference] = useState<number | null>(null)

  const startTimer = () => {
    if (endTime) {
      const intervalId = setInterval(() => {
        const currentTime = new Date()
        const difference = endTime.getTime() - currentTime.getTime()
        if (difference <= 0) {
          clearInterval(intervalId)
          setTimeDifference(0)
        } else {
          setTimeDifference(difference)
        }
      }, 1000)
      return () => clearInterval(intervalId)
    }
  }

  useEffect(() => {
    const storedEndTime = sessionStorage.getItem('endTimer')
    if (storedEndTime) {
      setEndTime(new Date(storedEndTime))
    }
  }, [])

  useEffect(() => {
    if (endTime) {
      const currentTime = new Date()
      const difference = endTime.getTime() - currentTime.getTime()
      if (difference > 0) {
        startTimer()
      } else {
        setTimeDifference(0)
      }
    }
  }, [endTime])

  const minutes = Math.floor((timeDifference ?? 0) / (1000 * 60))
  const seconds = Math.floor(((timeDifference ?? 0) % (1000 * 60)) / 1000)

  return !loadTimer ? (
    <div className=" w-full text-center">
      <div className={`leading-5 flex ${className}`}>
        <span className="flex flex-col items-center">
          {minutes < 10 ? '0' : ''}
          {minutes || '0'}
        </span>
        <span className="opacity-50 block mx-1"> : </span>
        <span className="flex flex-col items-center">
          {seconds < 10 ? '0' : ''}
          {seconds || '0'}
        </span>
      </div>
    </div>
  ) : null
}
