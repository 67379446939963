import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  COMPANY_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_NAME,
  QUIZ_NAME,
  QUIZ_VERSION,
} from '@/constants/variables'
import { Header } from '@/components/Header/Header'
import Layout from '@/components/Layout'
import { trackEvent, trackTimingComplete } from '@/helpers/facebookPixelEvents'
import { onFCP, onFID, onLCP, onTTFB } from 'web-vitals'
import { Metric } from 'web-vitals/src/types/base'
import { SessionStorageService } from '@/services/session-storage-service'
import { Answers } from '@/types/types'
import useAddUtmIfDev from '@/hooks/useAddUtmIfDev'
import ProgressiveImage from '@/components/ProgressiveImage/ProgressiveImage'
// import { StartLoading } from '@/components/QuestionsUI/StartLoading'

import startLabel from '@/assets/images/wallpilatesV6/start/label.svg'
import start1 from '@/assets/images/wallpilatesV6/start/start-1.png'
import start2 from '@/assets/images/wallpilatesV6/start/start-2.png'
import start3 from '@/assets/images/wallpilatesV6/start/start-3.png'
import start4 from '@/assets/images/wallpilatesV6/start/start-4.png'
import start1Webp from '@/assets/images/wallpilatesV6/start/start-1.webp'
import start2Webp from '@/assets/images/wallpilatesV6/start/start-2.webp'
import start3Webp from '@/assets/images/wallpilatesV6/start/start-3.webp'
import start4Webp from '@/assets/images/wallpilatesV6/start/start-4.webp'
import startLow1 from '@/assets/images/wallpilatesV6/start/start-1-mini.jpg'
import startLow2 from '@/assets/images/wallpilatesV6/start/start-2-mini.jpg'
import startLow3 from '@/assets/images/wallpilatesV6/start/start-3-mini.jpg'
import startLow4 from '@/assets/images/wallpilatesV6/start/start-4-mini.jpg'
import startLow1Webp from '@/assets/images/wallpilatesV6/start/start-1-mini.webp'
import startLow2Webp from '@/assets/images/wallpilatesV6/start/start-2-mini.webp'
import startLow3Webp from '@/assets/images/wallpilatesV6/start/start-3-mini.webp'
import startLow4Webp from '@/assets/images/wallpilatesV6/start/start-4-mini.webp'

interface StartScreenProps {
  nextPath: string
}

export const StartScreenV6 = (props: StartScreenProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  useAddUtmIfDev()

  const setToLocalStorageAgePlaceholder = (age: string) => {
    sessionStorage.setItem('placeholderAge', age)
  }

  const items = [
    {
      title: '18-29',
      imgLow: startLow1,
      img: start1,
      imgWebpLow: startLow1Webp,
      imgWebp: start1Webp,
      placeholder: '20',
    },
    {
      title: '30-39',
      imgLow: startLow2,
      imgWebpLow: startLow2Webp,
      img: start2,
      imgWebp: start2Webp,
      placeholder: '30',
    },
    {
      title: '40-49',
      imgLow: startLow3,
      imgWebpLow: startLow3Webp,
      img: start3,
      imgWebp: start3Webp,
      placeholder: '40',
    },
    {
      title: '50+',
      imgLow: startLow4,
      imgWebpLow: startLow4Webp,
      img: start4,
      imgWebp: start4Webp,
      placeholder: '50',
    },
  ]

  useEffect(() => {
    const startPath = ['/start', '/start/']
    let unmounted = false

    if (startPath.includes(location.pathname)) {
      const storedData = SessionStorageService.get('quizResult')
      const answers: Answers = storedData || false

      const paywallStatusString = sessionStorage.getItem('paywallStatus')
      if (!paywallStatusString || !answers) {
        trackEvent('start')

        const webVitalMetrics = [onFCP, onTTFB, onFID, onLCP]

        webVitalMetrics.forEach((metricFn) => {
          metricFn((data: Metric) => {
            if (unmounted) return

            const valueInSeconds = (data.value / 1000).toFixed(2)

            trackTimingComplete({
              type: data.name,
              value: parseFloat(valueInSeconds),
              quiz: QUIZ_NAME,
              version: QUIZ_VERSION,
            })
          })
        })
      } else {
        const newPaywallStatus = { currentStep: 0, showModal: false, wasShowing: true }
        newPaywallStatus.currentStep = 1
        newPaywallStatus.showModal = true
        newPaywallStatus.wasShowing = true
        sessionStorage.setItem('paywallStatus', JSON.stringify(newPaywallStatus))
        navigate('/paywall/', { replace: true })
      }
    }

    return () => {
      unmounted = true
    }
  }, [location])

  return (
    <>
      {/* <StartLoading /> */}

      <Layout>
        <div
          className={
            'opacity-100 visible p-5 absolute w-full h-full top-0 left-0 transition-all duration-300 flex flex-col overflow-y-auto overflow-x-hidden scrolling'
          }
        >
          <div className="absolute top-6 right-0 w-full move-burger">
            <Header isLogo={true} hideBtnHeader={true} />
          </div>
          <div className="mx-auto mt-14">
            <div className="title self-stretch text-[#17181d] text-center text-xl4 font-bold leading-[135%] font-girloy">
              Wall pilates plan
            </div>
            <div className="self-stretch text-[#5f626f] text-center text-xl2 font-medium leading-[130%] font-girloy">
              Based on your age, 1-min quiz
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5 mt-7 gap-y-10">
            {items.map((el) => (
              <div
                key={el.title}
                className="relative flex flex-col items-center h-[180px]"
                onClick={() => {
                  navigate(props.nextPath)
                  setToLocalStorageAgePlaceholder(el.placeholder)
                }}
              >
                <ProgressiveImage
                  className="h-[160px] object-cover"
                  lowSrc={el.imgLow}
                  highSrc={el.img}
                  lowWebp={el.imgWebpLow}
                  highWebp={el.imgWebp}
                  alt="Image"
                />
                <img
                  className="absolute w-full bottom-0 rounded-r20"
                  src={startLabel}
                  alt={''}
                  style={{
                    boxShadow:
                      '0px 10px 20px 0px rgba(102, 215, 77, 0.16), 0px 20px 50px 0px rgba(122, 236, 110, 0.26)',
                  }}
                />
                <p className="absolute bottom-2.5 text-center text-white text-xl font-bold font-girloy">
                  {el.title}
                </p>
              </div>
            ))}
          </div>

          <div className="flex flex-col justify-end mt-6">
            <p className="text-xs text-center text-[#5F626F] font-girloy">
              By choosing your age and continuing you agree to our{' '}
              <Link className="transition-all hover:text-primary" to="/offer-policy">
                {t('Offer Policy')}
              </Link>{' '}
              |{' '}
              <Link className="transition-all hover:text-primary" to="/privacy-policy">
                {t('Privacy Policy')}
              </Link>
            </p>

            {COMPANY_NAME && COMPANY_ADDRESS && COMPANY_EMAIL && (
              <div className="grid grid-cols-1 mt-3">
                <p className="text-xs2 text-center text-[#5F626F] font-girloy">{COMPANY_NAME}</p>
                <p className="text-xs2 text-center text-[#5F626F] font-girloy">{COMPANY_ADDRESS}</p>
                <p className="text-xs2 text-center text-[#5F626F] font-girloy">{COMPANY_EMAIL}</p>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}
