import React, { useEffect, useState } from 'react'
import { Answer, Answers, InputType } from '@/types/types'
import { useTranslation } from 'react-i18next'
import height from '@/assets/images/body-height.svg'
import { getValue } from '@/helpers/getObjectValue'
import { PRIMER_ID } from '@/constants/variables'
import { Button } from '@/components/Button/Button'

interface InputProps {
  attributes: InputType
  onAnswer: (answer: Answer, step?: number) => void
  handleRemoveAnswer: (answer: Answer) => void
  name: string
  error: string
  step: number
  disabled?: boolean
  answers: Answers
  version?: number
  onNextQuestionClick: () => void
  isShowNextButton?: boolean
  nextButtonTitle?: string
}

export default function Input(props: InputProps) {
  const { t } = useTranslation()
  const [showError, setShowError] = useState('')
  const [updatedPlaceholder, setUpdatedPlaceholder] = useState('')
  const [inputValue, setInputValue] = useState('')

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    let isValid = e.target.checkValidity()

    if (props.attributes.type === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      isValid = emailRegex.test(e.target.value)
    }

    if (props.attributes.type === 'name') {
      const nameRegex = PRIMER_ID ? /^[A-Za-z\s]+$/ : /^[\p{L}\s]+$/u
      isValid = nameRegex.test(e.target.value)
    }

    if (props.attributes.name === 'your-age') {
      const ageRegex = /^(1[6-9]|[2-9]\d|10[0-9]|110)$/
      isValid = ageRegex.test(e.target.value)
    }

    if (isValid) {
      props.onAnswer({ [props.name]: [e.target.value] })
      setShowError('')
    } else {
      props.onAnswer({ [props.name]: [] })

      if (props.attributes.type === 'email') {
        setShowError(t('invalidEmail'))
      } else if (props.attributes.type === 'name') {
        setShowError(PRIMER_ID ? t('invalidNamePrimer') : t('invalidName'))
      }
    }
  }

  const styles = {
    default: {
      container:
        "inline-flex items-center justify-center before:rounded-r10 before:border before:border-borderInput before:absolute before:content-[''] before:top-0 before:left-0 before:w-full before:h-full relative",
      item: 'relative transition-all  font-extrabold rounded-r10 py-1 px-4 w-16 flex items-center justify-center text-center uppercase',
      itemActive: 'bg-green text-white',
    },
    2: {
      container: 'rounded-[34px] relative bg-[#F2F6F3] w-full grid grid-cols-2 p-1.5',
      item: 'transition-all  font-extrabold rounded-r10 text-center normal-case text-[#5F626F] py-1.5',
      itemActive: 'bg-white text-[#61CE75] rounded-[34px]',
    },
    input:
      'rounded-xl border border-[var(--selector-border,rgba(215,229,218,0.8))] bg-white shadow-[inset_0px_4px_4px_0px_rgba(0,0,0,0.04)] placeholder:text-xl3 text-xl3 placeholder:normal-case py-5 text-center ',
  }

  const IntegrateButton = () => (
    <div className={'w-full text-white mt-5'}>
      <Button
        disabled={props?.isShowNextButton}
        onClick={() => {
          props.onNextQuestionClick()
        }}
        className={`
          btn-large text-xl2
          ${
            props?.isShowNextButton
              ? ' disabled:opacity-1 bg-[#00000008] disabled:text-[#8C8F9D] shadow-0'
              : ' disabled:opacity-1'
          } 
        `}
      >
        {props?.nextButtonTitle ?? t('NEXT')}
      </Button>
    </div>
  )

  useEffect(() => {
    const choosedValue = getValue(props.name, props.answers)
    if (choosedValue && typeof choosedValue === 'string') {
      setShowError('')
      setInputValue(choosedValue)
    }
    if (props.name === 'your-age') {
      const placeholder =
        sessionStorage.getItem('placeholderAge') !== null
          ? sessionStorage.getItem('placeholderAge')
          : ''
      setUpdatedPlaceholder(placeholder ?? '')
    }
  }, [])

  useEffect(() => {
    if (props.error) {
      setShowError(props.error)
    }
  }, [props.error])

  return (
    <div>
      {props.attributes.label && (
        <label className="!font-medium text-base opacity-65 mb-1">{props.attributes.label}</label>
      )}
      <input
        value={inputValue}
        type={props.attributes.type}
        inputMode={props.attributes.inputMode === 'numberic' ? 'numeric' : 'text'}
        onChange={(e) => handleChangeInput(e)}
        placeholder={updatedPlaceholder.length ? updatedPlaceholder : props.attributes.placeholder}
        minLength={props.attributes.minLength}
        required={props.attributes.required}
        className={`${showError ? 'border-mark' : ''} ${props.attributes.className || 'text-xl3 bg-lightGray text-center p-2 placeholder:capitalize'} block w-full border border-borderInput rounded-r12 font-extrabold outline-none text-dark placeholder:text-darkOpacity ${props.version === 2 ? styles.input : 'mb-5'}`}
      />
      {showError && (
        <span className="text-mark block mt-1 text-xs3 font-semibold">{showError}</span>
      )}
      {props.version === 2 && <IntegrateButton />}
      {props.attributes.tooltip &&
        (props?.version === 2 ? (
          <div className="p-4 mt-8 rounded-2xl border border-[var(--Color,#61CE75)] bg-white">
            <div className="relative">
              <h6 className="font-extrabold font-girloy text-[#61CE75] text-xl3 text-center">
                {t('To gauge your fitness levels accurately')}
              </h6>
              <p className="mt-1 text-center font-normal text-[#17181D] font-girloy text-xs">
                {t(
                  'Older people tend to have more body fat than younger people with the same BMI.',
                )}
              </p>
            </div>
          </div>
        ) : (
          <div
            className={`${inputValue.length === 0 ? 'opacity-0' : 'opacity-100'} transition-all p-4 border border-borderInput bg-lightGray mt-8 rounded-r12`}
          >
            <div className="flex items-start justify-start">
              <img
                className="min-w-6 max-w-6 mr-3"
                src={height}
                width="24"
                height="24"
                alt="Height icon"
              />
              <h6 className="font-extrabold text-xl2">
                {t('To gauge your fitness levels accurately')}
              </h6>
            </div>
            <p className="text-base text-dark mt-5 pl-9">
              {t('Older people tend to have more body fat than younger people with the same BMI.')}
            </p>
          </div>
        ))}
    </div>
  )
}
